import React, { useContext, useEffect, useState } from 'react';
import { imageDB } from "../components/imageDB";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';

import '../components/css/common.css';

import Label from './component/Label';
import { getUser, loginUser, registUser } from '../utility/userfirebase';
import { UserContext } from '../context/Users';



const Padding = styled.div`
  padding: 0px 10px;
`

const DataView =styled.div`
    border : 1px solid #a0a0a0;
    height : 80%;
    margin : 5px 5px 20px;
`

const Item = styled.div`
  display: flex;
  flex-direction : column;
  width:100%;
  margin-top:40px;
  height:50px;
`

const ItemTitle = styled.div`
  display: flex;
  font-size :16px;
`
const ItemContent = styled.div`
  display: flex;
  width :100%;
  border-bottom :1px solid #e7e7e7;
`


const BtnView = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  padding: 0px 5px;
  margin-bottom : 30px;
`
const AdjustBtn =styled.div`
    height :50px;
    width: 45%;
    border: 1px solid #ededed;
    display:flex;
    flex-direction : row;
    align-items : center;
    justify-content : center;
    border-radius: 5px;
    font-size:18px;
    margin-left:10px;

    background-color :#3f4850;
    color :#fff;
`

const MemberLogincontainer = () => {

  const navigate = useNavigate();

  const [width, setWidth] = useState(100);
  const [ref, setRef] = useState(null);


  const [userid, setUserid] = useState('kkan222');
  const onuseridchange = (e) => {setUserid(e.target.value);}
  const [userpw, setUserpw] = useState('11111111');
  const onuserpwchange = (e) => {setUserpw(e.target.value);}


  const {user, dispatch} = useContext(UserContext);

  const _handlelogin =async() =>{
   
    const USERID = userid + '@gmail.com';
    const PASSWORD = userpw;


    const loginData = await loginUser({USERID, PASSWORD});

    if(loginData == -1){
      alert("로그인에 실패 하였습니다");
    }else{


      const UserData = await getUser({USERID, PASSWORD});


      if(UserData != -1){
        alert("성공적으로 로그인 되었습니다");

        user.USERID = UserData.USERID;
        user.PASSWORD = UserData.PASSWORD;
        user.CEONAME = UserData.CEONAME;
        user.USER_ID = UserData.USER_ID;
        dispatch(UserData);
        navigate("/home");
      }else{
        alert("로그인 실패하였습니다");
      }

    }

  }



  useEffect(() => {

    //mount 시에
    console.log("init");

    window.scrollTo(0,0);

    return () => {

      //unmount 시에
      console.log("dispose");
    };
  }, []);

  return (
    <div className ="Container" style={{justifyContent: "center",
      alignItems: "center"}}>
      <div style={{marginTop:'10%'}}>
        <Label name={'머레이코리아 B2B 셀러 회원가입'} weight={600}/>
        <div style={{display:"flex", flexDirection:"row", padding:20, height:280}}>
     
          <div style={{display:"flex", height:600, flexDirection:"column", width:"500px"}}>

          <Item>
              <ItemTitle>회원 아이디</ItemTitle>
              <ItemContent>
                <input type="text"  placeholder='영문과 숫자조합으로만 입력바랍니다' className='InputCommon' onChange={onuseridchange} value={userid}></input>
              </ItemContent>
            </Item>

            <Item>
              <ItemTitle>회원 패스워드</ItemTitle>
              <ItemContent>
                <input type="text" placeholder='8자이상 입력바랍니다' className='InputCommon' onChange={onuserpwchange} value={userpw}></input>
              </ItemContent>
            </Item>
          </div>
        </div>

        <BtnView>
          <AdjustBtn onClick={_handlelogin}>로그인</AdjustBtn>
        </BtnView>
      </div>
    </div>
  );
}

export default MemberLogincontainer;
