

import React, { Fragment, useContext, useEffect, useState} from "react";
import { MaxPC, Mobile, PC, SmallPC } from "../../Responsive";
import { imageDB } from "../../imageDB";
import { COLORS } from "../../Theme";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Define } from "../../Define";
import Advertise from "../../../container/component/Advertise";
import ReactTyped from "react-typed";
import { UserContext } from "../../../context/Users";
import { signupUser } from "../../../utility/userfirebase";
import MainBanner from "../../../container/component/MainBanner";





const PCHeader = styled.div`
    height: 200px;
    text-align: center;
    background-color: #fff;
    display: flex;
    flex-direction:column;
    z-index:2;
    width:1400px;
`

const Container = styled.div`
  display:flex;
  flex-direction: row;  
  justify-content: space-between; 
  align-items: center;
  width :100%;
  padding: 20px 10% 0px;

`
const MaxContainer = styled.div`
  display:flex;
  flex-direction: row;  
  justify-content: space-between; 
  align-items: center;
  width :1400px;
  padding: 20px 10% 0px;

`

const ImageLine = styled.div`
  display:flex;
  padding-left: 5%;
  padding-top: 20px;
`
const ImageMaxLine = styled.div`
  display:flex;
  padding-left: 10%;
  padding-top: 20px;
`

const CategoryLine = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  width:60%;

`
const SelectCategory = styled.div`
  background-color: #323232;
  color: #fff;
  padding: 10px;
  border-radius: 5px;

`
const Category = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:17px;
  font-weight:900
`

const Row =styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items : center;
`

const SelectContainer = styled.div`
  display:flex;
  flex-direction: row;  
  justify-content: center; 
  align-items: center;
  width :100%;
  height:60px;
  background-color:#2a2a2a;
  position:fixed;
  z-index:5;
`
const EventContainer = styled.div`
  display:flex;
  flex-direction: row;  
  justify-content: center; 
  align-items: center;
  width :100%;
  height:50px;
  background-color:#191a1f;
  position:fixed;
  z-index:5;
  color :#fff;

`

const MenuContainer = styled.div`
  position:fixed;
  display:flex;
  flex-direction:column;
  width:100%;
  background-color:#fff;
  z-index:5;
  margin-top:55px;
`

const MaxMenuContainer = styled.div`
  position:fixed;
  display:flex;
  flex-direction:column;
  width:1400px;
  background-color:#fff;
  z-index:5;
  margin-top:50px;
  padding: 0px 10% 0px;
`

const SearchBasket = styled.div`
  height :30px;
  display: flex;
  justify-content: center;
  align-items : center;
  margin-right:200px;
`
const Infocontrol = styled.div`
  width: 200px;
  display: flex;
  justify-content: space-around;
  margin-right: 30px;
  align-items: center;
  padding-top: 40px;
  
`
const MaxInfocontrol = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-around;
  margin-right: 100px;
  align-items: center;
  padding-top: 40px;
  padding-left:820px;
  
`
const LineControl = styled.div`
  border: 1px solid rgb(255 47 47);
  width: 100%;
  position: absolute;
  margin-top: 45px;
`


const Homeheader = ({callback, type}) => {
  const [small, setSmall] = useState(false);

  const {dispatch, user} = useContext(UserContext);

  const [refresh, setRefresh] = useState(1);


  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 200)
      );
    }
  }, []);

  useEffect(() => {
    const handleShowButton = () => {
        if (window.scrollY > 10) {
            console.log("scroll 이동");

            document.getElementById("eventheader").style.display = "none";
            document.getElementById("menuheader").style.marginTop = "0px";
            document.getElementById("menuheader").style.height = "70px";
            document.getElementById("menuheader").style.width = "100%";
            document.getElementById("menuheader").style.borderBottom ="1px solid #ededed";
         

            document.getElementById("categoryheader").style.transition = "all 0.5s ease-out";
            document.getElementById("categoryheader").style.transform = "translate(150px, -57px)";
            document.getElementById("categoryheader").style.padding = "unset";
 
            document.getElementById("searchbasket").style.transition = "all 0.5s ease-out";
            document.getElementById("searchbasket").style.transform = "translate(0px, -65px)";
            document.getElementById("infoheader").style.display = "none";

        } else {
         document.getElementById("eventheader").style.display = "flex"; 
           document.getElementById("menuheader").style.marginTop = "55px";
           document.getElementById("menuheader").style.height = "115px";
           document.getElementById("menuheader").style.border = "none";
           document.getElementById("menuheader").style.width = "100%";
           document.getElementById("categoryheader").style.transform = "translate(-10px, 0px)";
           document.getElementById("searchbasket").style.transform = "translate(0px, 0px)";
           document.getElementById("infoheader").style.display = "flex";
           document.getElementById("infoheader").style.paddingLeft = "50%";
        }
    }


    window.addEventListener("scroll", handleShowButton)



    return () => {
        window.removeEventListener("scroll", handleShowButton)
    }
}, [])


  const navigate = useNavigate();

  const _handleLogin = ()=>{
    navigate("/Memberlogin");
  }
  const _handleRegister = ()=>{
    navigate("/Memberregister");
  }
  const _handleLogout = async()=>{
    const logoutuser = await signupUser();

    if(logoutuser != -1){

      user.USERID = "";
      user.USERPW = "";
      user.USERNAME = "";
      user.USER_ID = "";
      dispatch(user);

      navigate("/home");
    }
  }

  const _handleMain = () =>{
    navigate("/home");

    let categoryselect = [0,0,0,0,0,0,0,0];
    setSelect(categoryselect);
    setAllcategory(false);

    callback(Define.home);
  }
  const _handleAllCategory = () =>{


    // navigate('/categoryproduct', {state : {CATEGORYINFO: "전체"}});

    setAllcategory(true);
    let categoryselect = [0,0,0,0,0,0,0,0];
    setSelect(categoryselect);
    callback(Define.allcategory);

  }
  const _handleCategory = (index)=>{
    // let categoryselect = [0,0,0,0,0,0,0,0];
    // categoryselect[index] = 1;

    // setSelect(categoryselect);
    // setAllcategory(false);


    if(index ==1){callback(Define.allcategory);
      navigate("/introduce");
    }else if(index ==2){
      callback(Define.best);
      navigate("/portfolio");
    }
    // else if(index ==1){
    //   callback(Define.best);
    //   navigate("/bestproduct");
    // }
    // else if(index ==2){
    //   callback(Define.new);
    //   navigate("/newproduct");
    // }
    // else if(index ==3){
    //   callback(Define.md);
    //   navigate("/mdproduct");
    // }
    // else if(index ==4){
    //   callback(Define.gomargin);
    //   navigate("/marginproduct")
    // }
    // else if(index ==5){
    //   callback(Define.top)
    //   navigate("/topproduct");
    // }
    // else if(index ==6){
    //   callback(Define.soldout);
    //   navigate("/soldoutproduct");
    // }
    // else if(index ==7){
    //   callback(Define.out);
    //   navigate("/outproduct");
    // }

    setRefresh(refresh => refresh *1);

  }

  const [allcategory, setAllcategory] = useState(false);
  const categoryselect = [0,0,0,0,0,0,0,0];
  const [select, setSelect] = useState(categoryselect);

  useEffect(() => {

    console.log("type", type);
    if(type == Define.allcategory){
      const categoryselect = [0,0,0,0,0,0,0,0];
      categoryselect[0]=1;
      setSelect(categoryselect);
    }
    else if(type == Define.best){
      const categoryselect = [0,0,0,0,0,0,0,0];
      categoryselect[1]=1;
      setSelect(categoryselect);
    }else if(type == Define.new){
      const categoryselect = [0,0,0,0,0,0,0,0];
      categoryselect[2]=1;
      setSelect(categoryselect);
    }else if(type == Define.md){
      const categoryselect = [0,0,0,0,0,0,0,0];
      categoryselect[3]=1;
      setSelect(categoryselect);
    }else if(type == Define.gomargin){
      const categoryselect = [0,0,0,0,0,0,0,0];
      categoryselect[4]=1;
      setSelect(categoryselect);
    }else if(type == Define.top){
      const categoryselect = [0,0,0,0,0,0,0,0];
      categoryselect[5]=1;
      setSelect(categoryselect);
    }else if(type == Define.soldout){
      const categoryselect = [0,0,0,0,0,0,0,0];
      categoryselect[6]=1;
      setSelect(categoryselect);
    }else if(type == Define.out){
      const categoryselect = [0,0,0,0,0,0,0,0];
      categoryselect[7]=1;
      setSelect(categoryselect);
    }
  }, []);



  const categoryItems = ['바딜소개', '사업실적','채용정보','홍보센타', '기업비젼','글로벌네트워크','고객센타']

  return (
    <Fragment>
      <SmallPC>
        <PCHeader>
            {/* <SelectContainer id="selectheader">
              <img src={imageDB.desktop} style={{width:30, marginRight:20}}/>
              <img src={imageDB.mobile} style={{width:30}}/>
            </SelectContainer> */}
            <EventContainer id="eventheader"> 
         
            <ReactTyped strings={["바딜의 새로운 세상을 체험하세요"]}  typeSpeed={100} loop />
            
            <LineControl></LineControl>
            </EventContainer>
            
            <MenuContainer id="menuheader">

              <div style={{display:"flex", justifyContent:"space-between", width:"1440px"}}>
                <ImageLine onClick={_handleMain}>
                      <img src={imageDB.mainlogo} width={130} height={45}/>
                </ImageLine>
                <Infocontrol id="infoheader">
                   
                    {
                        user.USERID != ''  && 
                      
                        <div className="headerblink">{user.CEONAME}님이 로그인</div>

                  
                    }
                
                    {
                        user.USERID != ''  ?  (<div>로그아웃</div>)
                        :(<div >로그인</div>)
                    }

                   
                    <div>회원가입</div>
                  

                </Infocontrol>
              </div>
         
              <Container>
      
                  <CategoryLine id="categoryheader">

                    {/* {
                      type == Define.allcategory ? (
                        <SelectCategory onClick={()=>{_handleCategory(0)}}>
                        <Row>               
                          <Row style={{paddingLeft:5}}>전체카테고리</Row>
                        </Row>  
                      </SelectCategory>
                      ):(
                        <Category onClick={()=>{_handleCategory(0)}}>
                        <Row>               
                          <Row style={{paddingLeft:5}}>전체카테고리</Row>
                        </Row>  
                        </Category>
                      )
    
                    } */}
                  
                    {
                      categoryItems.map((category, index)=>(
                        <Category key={index} onClick={()=>{_handleCategory(index+1)}} >{category}</Category>
                      
                        
                      ))
                    }
                  </CategoryLine>

        
                  <SearchBasket id="searchbasket" >
                    <div >
                      <input type="text"  style={{width:120, backgroundColor:'transparent',
                      height:25,border: 'unset',
                      borderBottom: '2px solid #000000',
                      paddingLeft: "unset",
                      paddingTop:5,
                      paddingBottom:5,
                      paddingRight: "unset",
                      borderRadius: 'unset'}}></input>

                    
                    </div>
                    <div style={{position: 'relative',left: '-20px'}}>
                        <img src={imageDB.search} width={20} />
                    </div>

                    <div>
                        <img src={imageDB.basket} width={20} />
                    </div>
  
                  </SearchBasket>
        
              </Container>
    
            </MenuContainer>    


        </PCHeader>
      </SmallPC>
      <MaxPC>
      <PCHeader>
            {/* <SelectContainer id="selectheader">
              <img src={imageDB.desktop} style={{width:30, marginRight:20}}/>
              <img src={imageDB.mobile} style={{width:30}}/>
            </SelectContainer> */}
            <EventContainer id="eventheader"> 
         
            <ReactTyped strings={["바딜의 새로운 세상을 체험하세요"]}  typeSpeed={100} loop />
            
            <LineControl></LineControl>
            </EventContainer>
            
            <MaxMenuContainer id="menuheader">

              <div style={{display:"flex"}}>
                <ImageMaxLine onClick={_handleMain}>
                      <img src={imageDB.mainlogo} width={130} height={45}/>
                </ImageMaxLine>
                <MaxInfocontrol id="infoheader">
                   
                    {
                        user.USERID != ''  && <div className="maxheaderblink">{user.CEONAME}님이 로그인</div>
                    }
                
                    {
                        user.USERID != ''  ?  (<div >로그아웃</div>)
                        :(<div >로그인</div>)
                    }

                   
                    <div>회원가입</div>
          

                </MaxInfocontrol>
              </div>
         
              <MaxContainer>
      
                  <CategoryLine id="categoryheader">

                    {/* {
                      type == Define.allcategory ? (
                        <SelectCategory onClick={()=>{_handleCategory(0)}}>
                        <Row>               
                          <Row style={{paddingLeft:5}}>전체카테고리</Row>
                        </Row>  
                      </SelectCategory>
                      ):(
                        <Category onClick={()=>{_handleCategory(0)}}>
                        <Row>               
                          <Row style={{paddingLeft:5}}>전체카테고리</Row>
                        </Row>  
                        </Category>
                      )
    
                    } */}
                  
                    {
                      categoryItems.map((category, index)=>(
                        <>
                          {
                            select[index] == 0  ? (
                            <Category key={index} onClick={()=>{_handleCategory(index+1)}} >{category}</Category>
                            ):(
                            <SelectCategory key={index} onClick={()=>{_handleCategory(index+1)}} >{category}</SelectCategory>
                            )
                          }
                        </>
                      
                        
                      ))
                    }
                  </CategoryLine>

        
                  <SearchBasket id="searchbasket" >
                    <div >
                      <input type="text"  style={{width:120, backgroundColor:'transparent',
                      height:25,border: 'unset',
                      borderBottom: '2px solid #000000',
                      paddingLeft: "unset",
                      paddingTop:5,
                      paddingBottom:5,
                      paddingRight: "unset",
                      borderRadius: 'unset'}}></input>

                    
                    </div>
                    <div style={{position: 'relative',left: '-20px'}}>
                        <img src={imageDB.search} width={20} />
                    </div>

                    <div>
                        <img src={imageDB.basket} width={20} />
                    </div>
  
                  </SearchBasket>
        
              </MaxContainer>
    
            </MaxMenuContainer>    

            {/* <MainBanner/> */}

        </PCHeader>   
      </MaxPC>

    </Fragment>

  

  );
};

export default Homeheader;