

import React, { Fragment, useEffect} from "react";
import './Footer.css';
import { Mobile, PC } from "../../Responsive";


import { imageDB } from "../../../components/imageDB";
import { COLORS } from "../../Theme";
import styled from "styled-components";



const SitePCFooter = styled.div`
  background: ${(COLORS.footerbgcolor)};
  width: 100%;
  bottom:0%;
  color : #999;
  padding: 20px;

`
const Footer = ({menu, bottom, top}) => {

  return (
    <Fragment>
      <Mobile> 
          <footer>

            {
              bottom == true &&
              <div className={`site-mobile-footer ${menu == true ? "bottomapply": "bottomnoapply"}`}>
              <div className="content">유람</div>
              <div className="content">대표이사: 박인경 사업자 등록번호 380-86-01006</div>
              <div className="content">주소: 서울시 강남구 테헤란로8길 22 202호(역삼동 화인빌딩)</div>
              <div className="content">업무시간: 평일 10:30 - 16:30 TEL 02-3395-9540</div>
              <div className="content">준법감시팀 심의필 제 2022-광고-1472호</div>
              <div className="content">(2022.12.12-2023.12.11)</div>
              <div className="content">보험취급대리점 등록번호: 2012100213-케이지에스</div>
              <div className="content">알아두실 사항 | 개인정보처리방침</div>
              <div className="content">보험대리점 등록증 보기</div>
              </div>
            }
     
            
            {
              menu == true &&
              <div className="site-mobile-footer2">
                <div className="buttonview">
                  <div className="button">
                    <div className="imageicon">
                      <img src={imageDB.GlobeOn}  style={{width: 45,height: 45}}/>
                    </div>
                    <div className="buttonEnableText">
                      여행자보험
                    </div>
                  </div>
                  <div className="button">
                    <div className="imageicon">
                      <img src={imageDB.Taxoff}  style={{width: 45,height: 45}}/>
                    </div>
                    <div className="buttonDisableText">
                     면세점쿠폰
                    </div>
                  </div>
                  <div className="button">
                    <div className="imageicon">
                      <img src={imageDB.NationOff}  style={{width: 45,height: 45}}/>
                    </div>
                    <div className="buttonDisableText">
                      해외공관
                    </div>
                  </div>
                </div>

              </div>
            }
          
       
        
          
          </footer>
      </Mobile>

      <PC>
         <SitePCFooter top={top}>
         
            <div style={{display:'flex', flexDirection:"column", alignItems:"flex-start", lineHeight:2}}>
              <div className="content" style={{fontSize:30,display:'flex', flexDirection:"row"}}>

                <div>바딜</div>
  
              </div>
              <div className="content">대표이사: 박재훈 사업자 등록번호 663-09-01555</div>
              <div className="content">본사: 제주특별자치도 서귀포시 안덕면 한창로 100번길 27  TEL. 064-794-1225 </div>
              <div className="content">다산지사 : 경기도 남양주시 다산지금로 202 현대테라타워DIMC B동 715호 TEL. 031-555-1225  </div>
              <div className="content">별내지사 : 경기도 남양주시 별내면 청학로 132번길 22-1  TEL. 031-821-1226  </div>
              <div className="content">설립연도 : 2020년 11월 05일</div>
     
          
              </div>

          </SitePCFooter>
      </PC>


    </Fragment>

 

  );
};

Footer.defaultProps ={
  top: 2750
}


export default Footer;
