

import React, { useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import { imageDB } from "../../components/imageDB";
import Label from "./Label";

import NewsItem from "./NewsItem";
import YouTube from "react-youtube";
import Page from "../../components/Layout/Page";
import { getnews } from "../../utility/newsfirebase";
import SubLabel from "./SubLabel";

const PictureBox = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  border:1px solid #ededed;
  borderRadius:5px;
  height: 100px;
  margin-left:10px;
  margin-bottom:2px;
  background-color :#ededed;
`

const News = () => {

  const [newsitems, setNewsitems] = useState([]);
  const [refresh, setRefresh] = useState(1);

  const [news1, setNews1] = useState('');
  const [news1_id, setNews1_id] = useState('');
  const [type1, setType1] =useState('');
  const [news2, setNews2] = useState('');
  const [news2_id, setNews2_id] = useState('');
  const [type2, setType2] =useState('');

  useEffect(()=>{
    async function fetchData(){
      let newslist = [];

      newslist = await getnews();

      console.log("newslist", newslist);

      setNews1('');
      setNews2('');
      newslist.forEach((element)=>{
        if(element.ORDER == 1){
          setNews1(element.NEWS);
          console.log("news1", element.NEWS, news1);
          setNews1_id(element.NEWS_ID);
          setType1(element.TYPE);
          setRefresh(refresh => refresh * -1);
        }
        if(element.ORDER == 2){
          setNews2(element.NEWS);
          setNews2_id(element.NEWS_ID);
          setType2(element.TYPE);
          setRefresh(refresh => refresh * -1);
        }
      })



      console.log("newslist", newslist);
    }

    fetchData();
	}, [refresh]);


  return (
    <div style={{display:"flex", flexDirection:"column", marginTop:100,  marginBottom:40,}}>

      <div style={{display:"flex", height:60, flexDirection:"column",alignItems: "flex-start"}}>
        <Label name={'MURRAYKOREA NEWS'} weight={800}/>
        <div style={{height:10}}/>
        <SubLabel name={'머레이코리아 소식'}  weight={100} color={'#999'}/>

      </div>

      {/* <div style={{display:"flex", flexDirection:"row", marginTop:10, flexWrap:"wrap", marginBottom:40}}>

          <div style={{display:"flex", flexGrow:1}}>
            <YouTube
            videoId="27gfFkrz0x0" //동영상 주소
            opts={{
              width: "500px",
              height: "270px",
              playerVars: {
              autoplay: 1, //자동 재생 여부 
              modestbranding: 0, //컨트롤 바에 유튜브 로고 표시 여부
              loop: 1, //반복 재생
              playlist: "27gfFkrz0x0", //반복 재생으로 재생할 플레이 리스트
              },
            }}
            onReady={(e) => {
              e.target.mute(); //소리 끔
            }}
            />
          </div>
          <div style={{display:"flex", flexGrow:1}}>
            <img src={imageDB.news} width={500}/>
          </div>
       
       
      </div> */}

      <div style={{display:"flex", flexDirection:"row",
           padding:"30px 0px", flexDirection:"row"}}>

                <div style={{width:"50%"}}>
                <PictureBox  style={{height:300}}>

                  {
                    news1 =='' ? (<>
      
                    </>):(<>
                    {
                      type1 =='image' ? ( <img src={news1} style={{width:"100%", height:"100%"}}/>):
                      (
                       <iframe key={news1} src={`https://www.youtube.com/embed/${news1}?showinfo=0&enablejsapi=1&origin=http://localhost:3000`} width='100%' height='100%' ></iframe>
                      )
                    }
                     
                    </>)
                  }
               
                </PictureBox>
          
        
                </div>
       

                <div style={{width:"50%"}}>
                <PictureBox  style={{height:300}}>
                {
                    news2 =='' ? (<></>):(<>
                      {
                      type2 =='image' ? ( <img src={news2} style={{width:"100%", height:"100%"}}/>):
                      (
                       <iframe style={{border:'none'}} key={news2} src={`https://www.youtube.com/embed/${news2}?showinfo=0&enablejsapi=1&origin=http://localhost:3000`} width='100%' height='100%' ></iframe>
                      )
                    }
                    </>)
                  }
                </PictureBox>
         
                </div>
    

          </div>
  

    </div>
  
  );
};

export default News;
