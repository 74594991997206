

import React, { useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import Preview from "./Categoryview";
import Noticeview from "./Noticeview";
import BestItem from "./BestItem";
import Page from "../../components/Layout/Page";
import { getBest } from "../../utility/bestfirebase";
import SubLabel from "./SubLabel";
import Fade from 'react-reveal/Fade';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { getMd } from "../../utility/mdfirebase";
import BestItem2 from "./BestItem2";
import { MaxPC, SmallPC } from "../../components/Responsive";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y,Autoplay]); // *


const swiperStyle={
  position :"relative",
  width :'100%',
}

const NewProduct2 = () => {

  const [bestitems, setBestitems] = useState([]);
  const [refresh, setRefresh] = useState(1);
  useEffect(()=>{
    async function fetchData(){
      let bestitems = [];
      bestitems = await getMd();

      setBestitems(bestitems);

      console.log("bestitems", bestitems);
    }

    fetchData();
  }, [refresh]);

  return (
    <div style={{display:"flex",marginTop:20, flexDirection:"column"}}>
      <div style={{display:"flex", height:80, flexDirection:"column",alignItems: "flex-start", alignItems:"center",
        paddingTop:'70px'}}>
        <Label name={'신상품'} weight={800}/>
        <div style={{height:20}}/>
        <SubLabel name={'신규 출시한 따끈따끈한 상품입니다'}  weight={100} color={'#999'}/>
      </div>

      <MaxPC>
          <div style={{display:"flex",  flexDirection:"row", flexWrap:"no-wrap", backgroundColor:"white", padding:"20px 0px", justifyContent:"space-between"}}>
              {
                  bestitems.map((data, index = 0)=>(
                
                    <BestItem2  time={index} product_id ={data.PRODUCT_ID} img={data.PRODUCTIMG} name={data.PRODUCTNAME} select= {data.PRODUCTSELECT}
                    price={data.PRODUCTREGULARPRICE} lowprice = {data.PRODUCTLOWPRICE} component ={data.PRODUCTCOMPONENT} add={data.PRODUCT_ADD11_INFO}/>
                  
                  ))
                }
          </div>
      </MaxPC>
      <SmallPC>
          <div style={{display:"flex",  flexDirection:"row", flexWrap:"no-wrap", justifyContent:"space-around", backgroundColor:"white"}}>
              {
                  bestitems.map((data, index = 0)=>(
                
                    <BestItem2  time={index} product_id ={data.PRODUCT_ID} img={data.PRODUCTIMG} name={data.PRODUCTNAME} select= {data.PRODUCTSELECT}
                    price={data.PRODUCTREGULARPRICE} lowprice = {data.PRODUCTLOWPRICE} component ={data.PRODUCTCOMPONENT} add={data.PRODUCT_ADD11_INFO}/>
                  
                  ))
                }
          </div>
      </SmallPC>
  

    
    </div>
  );
};

export default NewProduct2;
