import React, { useEffect} from "react";
export const Define =  {
  allcategory  : "전체카테고리",
  best  : "BEST 100",
  new :"신규출시",
  md :"MD",
  gomargin :"GO!마진",
  top :"TOP10",
  soldout:"임시품절",
  out:"단종",
  home :"home",
  heart :"찜한상품",
}


