

import React, { useEffect} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import { useNavigate } from "react-router-dom";
import Fade from 'react-reveal/Fade';

const CategoryviewItem = ({img, name, main}) => {


  const navigate = useNavigate();
  const _handleCategory = () =>{
    console.log("category", name);
    if(name =='ICT사업부'){
      navigate('/categoryproduct', {state : {CATEGORYINFO: name}});
    }else if(name =='반려동물용품'){
      navigate('/companion', {state : {CATEGORYINFO: name}});
    }else if(name =='기계전자기기'){
      navigate('/electron', {state : {CATEGORYINFO: name}});
    }else if(name =='스마트물류사업'){
      navigate('/varivari', {state : {CATEGORYINFO: name}});
    }

    
  }
  return (

    <div onClick={_handleCategory} style={{display: "flex", alignItems: "center", justifyContent:"center",flexDirection:"column", width:"9%", marginTop:10}}>
      <Fade left delay={200}>
      <div style={{display:"flex", flexDirection:"column",marginRight:30}}>
        <div style={{
            borderRadius: 100, width:80, height:80,marginRight:10,marginBottom:10, backgroundColor:"#daebf2"}} className="Box">
          <div style={{position:"relative" }}>
            {
              main== true ? (<img src={img} width={80} height ={80} style={{borderRadius:100, paddingTop:33}}  />) :(<img src={img} width={80} height={80} style={{borderRadius:100}}  />)
            }
             
          </div>

        </div>
        <div style={{height:30, fontWeight:600, fontSize:14}}>{name}</div>
    </div>
    </Fade>
    </div>
   
 
  
  );
};

export default CategoryviewItem;
