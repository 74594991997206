import {db, auth, storage} from '../api/config';
import { collection, getDocs, query, updateDoc,where,doc,setDoc, deleteDoc, orderBy } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";



export const registBasket = async({
    USER_ID, 
    PRODUCT_ID,
    BASKET_COUNT,
    BASKET_OPTION,
    BASKET_PRICE,


}) =>{
    let success = false;

    const Ref = doc(collection(db, "BASKET"));
    const id = Ref.id;

    try{
      
        const newData = {
            BAKSET_ID : id,
            USER_ID, 
            PRODUCT_ID, 
            BASKET_COUNT, BASKET_OPTION, BASKET_PRICE,
            REGISTDATE : Date.now(),
        }

        await setDoc(Ref, newData);
    }catch(e){
   
        success =false;
        return null;

    }finally{
        return id;
    }

}

export const getBaskset = async({USER_ID})=>{
    const Ref = collection(db, "BASKET");

    let Item = [];
    let success = false;
    const q = query(Ref, where("USER_ID",'==',USER_ID));

    try{
        const querySnapshot =  await getDocs(q);
        querySnapshot.forEach((doc) => {   
            Item.push(doc.data());
        });
    
        if(querySnapshot.size > 0){
            success = true;
        }
    }catch(e){
         console.log("error", e.message);
    }finally{
        return new Promise((resolve, resject)=>{
            if(success){
                resolve(Item);
            }else{
                resolve(-1);  
            }          
        }) 
    }
}
