
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { imageDB } from "../components/imageDB";
import { useNavigate} from "react-router-dom";
import styled from 'styled-components';
import '../components/css/common.css';
import Search from '../components/Layout/SearchBasket';
import Best from './component/Best';
import BestItem from './component/BestItem';
import { getproduct } from '../utility/productfirebase';
import { CategoryContext } from '../context/Category';


const Label = styled.div`
  margin-top: 40px;
  font-size: 30px;
`
const Filter = styled.div`
  height:60px;
  display:flex;
  flex-direction:row;
  border :1px solid #E1E1E1;
  background-color :#fff;
  align-items:center;
  margin-top:20px;
  border-radius:10px;
`

const FilterItems= styled.div`
  width:70%;
  display:flex;
  justify-content:space-evenly;
  font-size:16px;
`
const FilterSelect= styled.div`
  font-size:16px;
  font-weight:900;
  text-decoration:underline;
`
const FilterNoselect = styled.div`
  font-size :14px;
`

const FilterItem= styled.div`
  font-size:${({select})=> select == true ? ('15px'):('14px')};
  font-weight:900;
  text-decoration:${({select})=> select == true ? 'underline':'none'}
`



const Categoryproductcontainer = ({items,Maincategory, Subcategory, Subitems, callback}) => {

  const {category, dispatch} = useContext(CategoryContext);
  const [productitems, setProductitems] = useState([]);
  const [refresh, setRefresh] = useState(1);

  useEffect(()=>{
    async function fetchData(){
      let productitemsTmp = [];
      let productitems = [];
      productitemsTmp = await getproduct();

      productitemsTmp.forEach(element => {
          if(Subcategory == ''){
            if(element.PRODUCTONESELECT == Maincategory){
              productitems.push(element);
            }
          }else{
            if(element.PRODUCTONESELECT == Maincategory && element.PRODUCTTWOSELECT == Subcategory){
              productitems.push(element);
            }
          }
      });

      setProductitems(productitems);
      console.log("productitems", productitems);
    }
     fetchData();
  }, [refresh]);

  function existSubcategory(){
    return Subcategory == '' ?  false : true;
  }
  function sameSubcategory(data){
    return Subcategory ==  data ?  true : false;
  }

  const allcategoryclick = ()=>{
    callback(Maincategory,"", Subitems);
    setRefresh(refresh => refresh * -1);
  }

  const categoryclick = (data) =>{
    callback(Maincategory,data, Subitems);
    setRefresh(refresh => refresh * -1);
  }

  return (
    <div className ="Container">
      {/* <Search height={50} /> */}
      <div style={{padding:'0px 30px'}}>
      <Label>{Maincategory}</Label>
      {/* <Filter>
        <FilterItems>
          <FilterItem select={!existSubcategory()} onClick={allcategoryclick}>전체</FilterItem>
          {
              Subitems.map((data,index)=>(
              <Fragment key={index}>
                <FilterItem select={sameSubcategory(data)} onClick={()=>{categoryclick(data)}}>{data}</FilterItem>
              </Fragment>
 
            ))
          }

        </FilterItems>
      </Filter> */}
        <div style={{display:"flex", flexDirection:"row", marginTop:30, flexWrap:"wrap", minHeight:800}}>        
        {
          productitems.map((data, index)=>(
            <BestItem  product_id ={data.PRODUCT_ID} img={data.PRODUCTIMAGEARY[0]} name={data.PRODUCTNAME} select= {data.PRODUCTSELECT}
            price={data.PRODUCTREGULARPRICE} lowprice = {data.PRODUCTLOWPRICE} component ={data.PRODUCTCOMPONENT} add={data.PRODUCT_ADD11_INFO}/>
          ))
        } 
        </div> 
      </div>




      

    </div>
  );
}

export default Categoryproductcontainer;
