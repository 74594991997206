import {db, auth, storage} from '../api/config';
import { collection, getDocs, query, updateDoc,where,doc,setDoc, deleteDoc, orderBy } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";



export const registUser = async({
    USERID, 
    PASSWORD, 
    TEL, RANK, BUSINESSNUM, CEONAME, STORENAME, BANKLICENSE,BUSINESSLICENSE,BANK,BRAND,EMAIL

}) =>{
    let success = false;

    const Ref = doc(collection(db, "USER"));
    const id = Ref.id;

    try{
        const {user} = await createUserWithEmailAndPassword(auth, USERID, PASSWORD);
      
        const newData = {
            USER_ID : id,
            USERID, 
            PASSWORD, 
            TEL, RANK, BUSINESSNUM, CEONAME, STORENAME, BANKLICENSE,BUSINESSLICENSE,BANK,BRAND,EMAIL,
            REGISTDATE : Date.now(),
        }

        await setDoc(Ref, newData);
    }catch(e){
        console.log("auth ", e.message);
        alert( e.message);
        success =false;
        return null;

    }finally{
        return id;
    }

}
export const loginUser = async({
    USERID,
    PASSWORD,
 
 }) =>{

    console.log("USER INFO", USERID, PASSWORD);

     let success = false;

     let user = {};
     
     try{
         user = await signInWithEmailAndPassword(auth,USERID, PASSWORD);
         success = true;
 
     }catch(e){
 
         console.log("error", e);
     }finally{
 
         return new Promise((resolve, reject)=>{
 
             if(success){
                 resolve(user);
             }else{
                 resolve(-1);
             }
         })
     }
    

 
 }
export const getUser = async({USERID,
    USERPW})=>{
    const Ref = collection(db, "USER");

    let Item = null;
    let success = false;
    const q = query(Ref, where("USERID",'==',USERID));

    try{
        const querySnapshot =  await getDocs(q);
        querySnapshot.forEach((doc) => {
            
            Item = doc.data();
        });
    
        if(querySnapshot.size > 0){
            success = true;
        }
    }catch(e){
         console.log("error", e.message);
    }finally{
        return new Promise((resolve, resject)=>{
            if(success){
                resolve(Item);
            }else{
                resolve(-1);  
            }          
        }) 
    }
}

export const signupUser = async()=>{
        return new Promise((resolve, resject)=>{

            const auth = getAuth();
            signOut(auth).then(() => {
                // Sign-out successful.

                 resolve(0);
            }).catch((error) => {
                // An error happened.
                 console.log(error);
                 resolve(-1);
            });
    
        }) 
    
}

export const registbellabletmp = async({
    USER_ID,
    USERNAME,
    INTRODUCE,
    HISTORY,
    PROFILEIMAGEARY,
    CONCERTIMAGEARY,
    CONCERTVIDEOARY,
    EDUINTRODUCE,
    EDUVIDEOARY,

})=>{
    let success = false;
    const Ref = doc(collection(db, "BELLABLETMP"));
    const id = Ref.id;

    try{
    
        const newData = {
            BELLABLETMP_ID : id,
            USER_ID:USER_ID,
            USERNAME:USERNAME,
            INTRODUCE:INTRODUCE,
            HISTORY:HISTORY,
            PROFILEIMAGEARY:PROFILEIMAGEARY,
            CONCERTIMAGEARY:CONCERTIMAGEARY,
            CONCERTVIDEOARY:CONCERTVIDEOARY,
            EDUVIDEOARY: EDUVIDEOARY,
            EDUINTRODUCE : EDUINTRODUCE,
            REGISTDATE : Date.now(),
        }

        await setDoc(Ref, newData);
    }catch(e){
        console.log("auth ", e.message);

        alert( e.message);
        success =false;
        return null;

    }finally{
        return id;
    }
}
export const updatebellabletmp = async({
    BELLABLETMP_ID,
    USER_ID,
    USERNAME,
    INTRODUCE,
    HISTORY,
    PROFILEIMAGEARY,
    CONCERTIMAGEARY,
    CONCERTVIDEOARY,
    EDUINTRODUCE,
    EDUVIDEOARY,

})=>{
    let success = false;

    console.log("BELLABLETMP_ID",BELLABLETMP_ID);

    const Ref = collection(db, "BELLABLETMP");
    const rows = query(Ref, where("BELLABLETMP_ID",'==', BELLABLETMP_ID ));
    try{
    
        const querySnapshot =  await getDocs(rows);
        querySnapshot.forEach(function (doc) {

            console.log("update", doc.data())
            updateDoc(doc.ref, {
                USER_ID:USER_ID,
                USERNAME:USERNAME,
                INTRODUCE:INTRODUCE,
                HISTORY:HISTORY,
                PROFILEIMAGEARY:PROFILEIMAGEARY,
                CONCERTIMAGEARY:CONCERTIMAGEARY,
                CONCERTVIDEOARY:CONCERTVIDEOARY,
                EDUVIDEOARY: EDUVIDEOARY,
                EDUINTRODUCE : EDUINTRODUCE,
                REGISTDATE : Date.now(),
            });
        });


    }catch(e){
        console.log("auth ", e.message);

        alert( e.message);
        success =false;
        return null;

    }finally{
        return 0;
    }
}

export const registbellable = async({
    USER_ID,
    USERNAME,
    INTRODUCE,
    HISTORY,
    PROFILEIMAGEARY,
    CONCERTIMAGEARY,
    CONCERTVIDEOARY,
    EDUINTRODUCE,
    EDUVIDEOARY,

})=>{
    let success = false;
    const Ref = doc(collection(db, "BELLABLE"));
    const id = Ref.id;

    try{
    
        const newData = {
            BELLABLE_ID : id,
            USER_ID:USER_ID,
            USERNAME:USERNAME,
            INTRODUCE:INTRODUCE,
            HISTORY:HISTORY,
            PROFILEIMAGEARY:PROFILEIMAGEARY,
            CONCERTIMAGEARY:CONCERTIMAGEARY,
            CONCERTVIDEOARY:CONCERTVIDEOARY,
            EDUVIDEOARY: EDUVIDEOARY,
            EDUINTRODUCE : EDUINTRODUCE,
            REGISTDATE : Date.now(),
        }

        await setDoc(Ref, newData);
    }catch(e){
        console.log("auth ", e.message);

        alert( e.message);
        success =false;
        return null;

    }finally{
        return id;
    }
}
export const updatebellable = async({
    BELLABLE_ID,
    USER_ID,
    USERNAME,
    INTRODUCE,
    HISTORY,
    PROFILEIMAGEARY,
    CONCERTIMAGEARY,
    CONCERTVIDEOARY,
    EDUINTRODUCE,
    EDUVIDEOARY,

})=>{
    let success = false;

    console.log("BELLABLE_ID",BELLABLE_ID);

    const Ref = collection(db, "BELLABLE");
    const rows = query(Ref, where("BELLABLE_ID",'==', BELLABLE_ID ));
    try{
    
        const querySnapshot =  await getDocs(rows);
        querySnapshot.forEach(function (doc) {

            console.log("update", doc.data())
            updateDoc(doc.ref, {
                USER_ID:USER_ID,
                USERNAME:USERNAME,
                INTRODUCE:INTRODUCE,
                HISTORY:HISTORY,
                PROFILEIMAGEARY:PROFILEIMAGEARY,
                CONCERTIMAGEARY:CONCERTIMAGEARY,
                CONCERTVIDEOARY:CONCERTVIDEOARY,
                EDUVIDEOARY: EDUVIDEOARY,
                EDUINTRODUCE : EDUINTRODUCE,
                REGISTDATE : Date.now(),
            });
        });


    }catch(e){
        console.log("auth ", e.message);

        alert( e.message);
        success =false;
        return null;

    }finally{
        return 0;
    }
}

export const getbellabletmp = async({USER_ID})=>{
    const Ref = collection(db, "BELLABLETMP");

    let Item = null;
    let success = false;
    const q = query(Ref, where("USER_ID",'==',USER_ID));

    try{
        const querySnapshot =  await getDocs(q);
        querySnapshot.forEach((doc) => {
            
            Item = doc.data();
        });
    
        if(querySnapshot.size > 0){
            success = true;
        }
    }catch(e){
         console.log("error", e.message);
    }finally{
        return new Promise((resolve, resject)=>{
            if(success){
                resolve(Item);
            }else{
                resolve(-1);  
            }          
        }) 
    }
}
export const getbellable = async({USER_ID})=>{
    const Ref = collection(db, "BELLABLE");

    let Item = null;
    let success = false;
    const q = query(Ref, where("USER_ID",'==',USER_ID));

    try{
        const querySnapshot =  await getDocs(q);
        querySnapshot.forEach((doc) => {   
            Item = doc.data();
        });
    
        if(querySnapshot.size > 0){
            success = true;
        }
    }catch(e){
         console.log("error", e.message);
    }finally{
        return new Promise((resolve, resject)=>{
            if(success){
                resolve(Item);
            }else{
                resolve(-1);  
            }          
        }) 
    }
}
export const getallbellable = async()=>{
    const Ref = collection(db, "BELLABLE");

    let items = [];
    let success = false;
    const q = query(Ref, orderBy("REGISTDATE",'desc'));

    try{
        const querySnapshot =  await getDocs(q);
        querySnapshot.forEach((doc) => {
            
            items.push(doc.data());
        });
    
        if(querySnapshot.size > 0){
            success = true;
        }
    }catch(e){
         console.log("error", e.message);
    }finally{
        return new Promise((resolve, resject)=>{
            if(success){
                resolve(items);
            }else{
                resolve(-1);  
            }          
        }) 
    }
}
