import React from 'react';
import Homecontainer from '../container/Homecontainer';


import ProductLayout from '../components/Layout/ProductLayout';
import Basketcontainer from '../container/Basketcontainer';
import Layout from '../components/Layout/Layout';


const Basketpage = () => {
  return (
    <Layout>
      <Basketcontainer/>
    </Layout>

  );
}

export default Basketpage;
