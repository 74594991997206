import React from 'react';

import Layout from '../components/Layout/Layout';


import { Define } from '../components/Define';
import Itemproductcontainer from '../container/Itemproductcontainer';
import Homelayout from '../components/Layout/Homelayout';


const Itemproductpage = ({pagetype}) => {
  return (
    <Homelayout type={pagetype}>
      <Itemproductcontainer pagetype={pagetype}/>
    </Homelayout>

  );
}

export default Itemproductpage;
