

import React, { Fragment, useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import './BestItem.css'
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import { CommaFormatted, ratevalue } from "../../utility/common";
import { useNavigate } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import { MaxPC, SmallPC } from "../../components/Responsive";

const Container = styled.div`
  border-radius: 5px;
  flex-direction:column;
  margin-right:20px;
  display: flex;
  border-radius:10px;
  margin-bottom:20px;
`

const CheckItem = styled.div`
  background-color: #b5b5b7c9;
  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`

const UnCheckItem = styled.div`

  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`
const Sticker1 = styled.div`
  background-color: #ff00007d;
  color: #fff;
  font-size: 12px;
  top: -8px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 30px;
`
const ProductInfo = styled.div`
  padding: 15px 0px;
  border-top: none;
`
const ProductName = styled.div`
  font-size: 18px;
  text-align: left;
  padding-left: 10px;
  color: #000;
  font-weight:900;
  font-family: Pretendard-Bold;
`

const ProductPrice = styled.span`
  font-size: 16px;
  text-align: left;
  color: #000;
  font-weight:900;
  font-family: Pretendard-Bold;
`

const ProductRate = styled.span`
  font-size: 16px;
  text-align: left;
  color: #ff0000;
  font-family: Pretendard-Bold;
  font-weight:900;
  padding-left:5px;
`
const ProductSubPrice = styled.span`
  font-size: 14px;
  text-align: left;
  padding-left:10px;
  color: #929292;
  text-decoration:line-through;
`

const BuyItem4 = ({index, img, speed}) => {

  const navigate = useNavigate();

  const [isHovering, setIsHovering] = useState(false);
  const _handleMouseOver = () =>{
    if(isHovering){
      return;
    }
    setIsHovering(true); 

  }
  const _handleMouseOut= () =>{

    if(!isHovering){
      return;
    }
    setIsHovering(false);

  
  }


  const _handleProduct = () =>{

  }


  return (

    <Fragment>

          <Container >
            <div style={{display:"flex"}}>
              <div className="itemcontainer">
                <SmallPC><img src={img} class="image" style={{height:"450px", width:"350px", padding:10}} /></SmallPC>
                <MaxPC><img src={img} class="image" style={{height:"450px", width:"350px", padding:10}} /></MaxPC>
              </div>
            </div>

          </Container>

    </Fragment>
  );
};

BuyItem4.defaultProps={
  price : 0,
  time :0,
}
export default BuyItem4;
