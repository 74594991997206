

import React, { Fragment, useContext, useEffect, useState} from "react";
import { Mobile, PC } from "../../Responsive";
import { imageDB } from "../../imageDB";
import { COLORS } from "../../Theme";
import styled from "styled-components";
import Chat from "../../../container/component/Chat";
import Button from "../Button";
import Guide from "../../../container/component/Guide";
import ControlView from "../../../container/component/ControlView";
import { getproductbyid } from "../../../utility/productfirebase";
import { CommaFormatted } from '../../../utility/common';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/Users";
import { registBasket } from "../../../utility/basketfirebase";
import { registHeart } from "../../../utility/heartfirebase";


const PCSide = styled.div`
  height: 300px;
  background-color: white;
  align-items: right;
  display: flex;
  position: absolute;
  right: 0;
  width: 50px;
  border-radius: 10px;
  z-index:2;
`
const PCSideL = styled.div`
  height: 300px;
  background-color: white;
  align-items: right;
  display: flex;
  position: fixed;
  right: 15%;
  width: 50px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index:2;
`
const PCSideExpand = styled.div`
  height: 620px;
  background-color: white;
  align-items: right;
  display: flex;
  flex-direction : column;

  width: 26%;
  border-bottom-left-radius: 15px;
  align-items: center;
  border: 1px solid #DEDEDE;
  z-index:2;

`

const Container = styled.div`
  display:flex;
  flex-direction: column;  
  justify-content: flex-start; 
  align-items: center;
  border-radius :10px;

`
const MainText = styled.span`
  font-weight :700;
  color : black;
`

const CheckBtn = styled.div`
    background-color: #323232;
    width: 30px;
    height: 30px;
    margin: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Btn = styled.div`
    width: 30px;
    height: 30px;
    margin: 10px;
    border-radius: 10px;
    display : flex;
    justify-content: center;
    align-items : center;
`

const Line = styled.div`
  background-color : #E7E7E7;
  height :1px;
  width : 30px;
`
const BankAccount = styled.div`
  display: flex;
  flex-direction : column;
  width:90%;
  margin-top:40px;

`
const Nego = styled.div`
  display: flex;
  flex-direction : column;
  width:90%;
  margin-top:20px;

`
const Customer = styled.div`
  display: flex;
  flex-direction : column;
  width:90%;
  margin-top:20px;

`
const Price = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
flex-direction: row;
width: 100px;

`    

const ProductRightside = ({product_id}) => {

  const navigation = useNavigate();

  const [expand, setExpand] = useState(false);
  const [product, setProduct] = useState({});
  const [refresh, setRefresh] = useState(false);

  const [basketlist, setBasketlist] = useState([]);

  const [count, setCount] = useState(0);
  const [price, setPrice] = useState(0);
  const PRODUCT_ID = product_id;

  const {dispatch, user} = useContext(UserContext);

  const _handlebasket = async()=>{

    if(user.USERID ==''){
      alert("로그인이 필요합니다");
      return;
    }

    const USER_ID = user.USER_ID;
    const PRODUCT_ID = product.PRODUCT_ID;
    const BASKET_COUNT = basketlist[0].productcount;
    const BASKET_OPTION = basketlist[0].productoptionname;
    const BASKET_PRICE= basketlist[0].productprice;
    const basket = await registBasket({USER_ID, PRODUCT_ID, BASKET_COUNT, BASKET_OPTION,
    BASKET_PRICE});

    alert("장바구니에 저장 되었습니다");

    navigation("/basket");
  }
  const _handleheart = async()=>{
    if(user.USERID ==''){
      alert("로그인이 필요합니다");
      return;
    }

    const USER_ID = user.USER_ID;
    const PRODUCT_ID = product.PRODUCT_ID;

    const heart = await registHeart({USER_ID, PRODUCT_ID});

    alert("찜처리가 완료 되었습니다");
  }
  const _handleMinus = () =>{

    if(count ==0){
      return;
    }
    let basketitem ={
      productname : "",
      productid :"",
      productprice:0,
      productoptionname:"",
      productoptionprice:0,
      productcount :0,
    }

    const FindIndex = basketlist.findIndex(x=>x.productoptionname == productoptionservice1
    || x.productoptionname == productoptionservice2);

    if(FindIndex !=-1){
      basketlist[FindIndex].productprice -= parseInt(product.PRODUCTSUPPLYPRICE);
      basketlist[FindIndex].productcount = (count-1);
      setCount(count-1);
      setPrice(basketlist[FindIndex].productprice );
      basketlist.push(basketitem);
    }else{

    }
  



    setBasketlist(basketlist);
  }
  const _handlePlus = () =>{

    let basketitem ={
      productname : "",
      productprice:0,
      productid :"",
      productoptionname:"",
      productoptionprice:0,
      productcount :0,
    }


    const FindIndex = basketlist.findIndex(x=>x.productoptionname == productoptionservice1
      || x.productoptionname == productoptionservice2);
  
      if(FindIndex !=-1){
        basketlist[FindIndex].productprice += parseInt(product.PRODUCTSUPPLYPRICE);
        basketlist[FindIndex].productcount += (count+1);
        basketlist[FindIndex].productid = PRODUCT_ID;
        basketlist[FindIndex].productoptionname =  productoptionservice1;
        basketlist[FindIndex].productname +=  product.PRODUCTNAME;
        setCount(count+1);
        setPrice(basketlist[FindIndex].productprice );
        basketlist.push(basketitem);
      }else{
        basketitem.productprice  = parseInt(product.PRODUCTSUPPLYPRICE);
        basketitem.productoptionname = productoptionservice1;
      
        setCount(1);
        setPrice(basketitem.productprice );
        basketlist.push(basketitem);
      }
  
      console.log("basketlist", basketlist);
      



  }

  const [productoptionservice2, setProductoptionservice2] = useState('');
  const [productoptionservice1, setProductoptionservice1] = useState('');
  const [optionservices, setOptionServices] = useState([]);
  const onproductoptionservice1Change = (e) => {
    console.log("select", e.target.value);
    setProductoptionservice1(e.target.value);}
  const onproductoptionservice2Change = (e) => {setProductoptionservice2(e.target.value);}
  
  useEffect(()=>{
    async function fetchData(){
        const product = await getproductbyid(PRODUCT_ID);
        console.log("product info", product);
        setProduct(product);
        setRefresh(true);
    }
    fetchData();
  }, [])

  const _handleExpand = () =>{
    setExpand(!expand);
  }
  const callback = ()=>{

  }
 

  return (
    <Fragment>
      <Mobile>
          <Container>
          {'Header'}   
          </Container>
      </Mobile>
      <PC>
   
      
        
          <Fragment>
    
            <PCSideExpand>
              <div style={{display:'flex', width:'90%', marginTop:20}}>
              <div style={{fontWeight:700}}>주문서</div>
           
              </div>
              <div style={{height:2, width:'90%', backgroundColor: '#323232', marginTop:10}}></div>

              <div style={{display:'flex', flexDirection:"column", width:'90%', marginTop:10}}>

                {
                  product.PRODUCT_OPTIONLIST == undefined ? (
                    <div style={{justifyContent:"flex-start", display:"flex"}}>단일상품</div>
                  ):( <><div style={{justifyContent:"flex-start", display:"flex"}}>옵션상품</div>
                     <div  style={{backgroundColor: "white",
                     fontSize:'13px',
                     display: "flex",
                     flexDirection: "column",
                     justifyContent: "flex-start",
                     alignItems: "flex-start",
                     padding: '5px',
                     border: '1px solid #DADADA',width: '100%', marginBottom:10}} 
                     >
                     {
                     product.PRODUCT_OPTIONLIST.map((data, index)=>(
                       <div
                       value={data.optioncategory} key={index}>
                         {data.optioncategory} /
                         {data.optionname} /
                         가격 : {CommaFormatted(data.optionprice)}원 /
                         재고 : {data.optionqty}개보유
                       </div>
                     )) 
                     }
                     </div>
                    </>
                    
                  )
                }
              
                <div style={{display:"flex", justifyContent:"flex-start", textAlign:"left"}}>{product.PRODUCTNAME}</div>

                {
                    product.PRODUCT_OPTIONLIST != undefined &&
                    <div style={{marginBottom:10, marginTop:10}}>
                    <select  style={{width:"100%", height:40, paddingLeft:5}} className='commonselect' onChange={onproductoptionservice1Change} value={productoptionservice1}>
                    {
                    product.PRODUCT_OPTIONLIST.map((data, index)=>(
                      <option value={data.optionname} key={index}>
                        {data.optionname}
                      </option>
                    )) 
                    }
                    </select>
                    </div>
                  
                  }


                <div style={{display:"flex", flexDirection:"row", marginTop:30}}>
                  <div style={{display:"flex", flexDirection:"row", height:30, justifyContent:"center", alignItems:"center"}}>
                    <div onClick={_handleMinus} style={{border: "1px solid #ededed", width:50,height:"30px",
                  display: "flex", justifyContent: "center",alignItems: "center"}}>-</div>
                    <div style={{border: "1px solid #ededed", width:60,height:"30px",
                  display: "flex", justifyContent: "center",alignItems: "center"}}>{count}</div>
                    <div onClick={_handlePlus} style={{border: "1px solid #ededed", width:50,height:"30px",
                  display: "flex", justifyContent: "center",alignItems: "center"}}>+</div>
                  </div>
                  <Price>{CommaFormatted(price)}원</Price>
                </div>

          

              </div>



              {/* <img src={imageDB.basketsample} style={{width:250, margin:'20px 0px'}} /> */}

              <div style={{display:'flex', width:'90%', marginTop:10}}>
                <Button Label={'장바구니 담기'} bgcolor={'#000'}
                borderRadius={10}
                color={'#fff'} bordercolor={'#000'} height={40} callback={_handlebasket}/>
              </div>


              <div style={{display:'flex', width:'90%', marginTop:10}}>
                <Button Label={'찜하기'} bgcolor={'#fff'}
                borderRadius={10}
                color={'#000'} bordercolor={'#000'} height={40} callback={_handleheart}/>
              </div>


     
              
            </PCSideExpand>
          </Fragment>

      
        

      </PC>

    </Fragment>

  

  );
};

export default ProductRightside;