

import React, { useEffect} from "react";
import styled from "styled-components";
import { COLORS, SIZE } from "../Theme";

const Container = styled.div`
  height :${({height}) =>height}px;
  width :100%;
  background-color : ${({bgcolor}) =>bgcolor};
  display:flex;
  flex-direction : row;
  align-items : center;
  justify-content : center;
  border-radius: ${({borderRadius}) => borderRadius}px;
  border: 1px solid  ${({bordercolor}) =>bordercolor};
`

const LabelText = styled.span`
  color :  ${({color}) =>color};
  font-size :${(SIZE.smallsize)}px;
  font-weight: 900;
`

const Button = ({Label, bgcolor, color, bordercolor, height, callback,borderRadius}) => {

  const _handleclick = () =>{
    callback();
  }

  return (
    
    <Container bgcolor = {bgcolor}  height={height} bordercolor={bordercolor}
    borderRadius ={borderRadius}
    onClick={_handleclick}>
      <LabelText color={color} >{Label}</LabelText>
    </Container>
  );
};

Button.defaultProps={
  bordercolor : '#000',
  borderRadius :5
}
export default Button;
