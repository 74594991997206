

import React, { useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import Preview from "./Categoryview";
import Noticeview from "./Noticeview";
import BestItem from "./BestItem";
import Page from "../../components/Layout/Page";
import { getBest } from "../../utility/bestfirebase";
import SubLabel from "./SubLabel";
import Fade from 'react-reveal/Fade';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import Nav from "../Nav";
import DataItem from "./DataItem";
import BuyItem from "./BuyItem";
import BuyItem2 from "./BuyItem2";
import BuyItem3 from "./BuyItem3";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y,Autoplay]); // *

const swiperStyle={
  position :"relative",
  width :'100%',
}
const NavButtonView = styled.div`
  width: 120px;
  height: 50px;
  display:flex;
  justify-content:space-around;
`
const NavButton = styled.div`
  border-radius: 50px;
  height: 50px;
  width: 50px;
`



const ICT = () => {



  const [best2items, setBest2items] = useState([
    {
      IMG : imageDB.caryou1
    },
    {
      IMG : imageDB.caryou2
    },
    {
      IMG : imageDB.caryou3
    },
    {
      IMG : imageDB.caryou4
    },
    {
      IMG : imageDB.caryou5
    },{IMG : imageDB.caryou6},{IMG : imageDB.caryou7},{IMG : imageDB.caryou8},{IMG : imageDB.caryou9},{IMG : imageDB.caryou10},{IMG : imageDB.caryou10}

  ]);
  const [best3items, setBest3items] = useState([
    {
      IMG : imageDB.order1
    },{IMG : imageDB.order2},{IMG : imageDB.order3},{IMG : imageDB.order4},{IMG : imageDB.order5},{IMG : imageDB.order6}

  ]);
  const [best4items, setBest4items] = useState([
    {
      IMG : imageDB.marone1
    },{IMG : imageDB.marone2},{IMG : imageDB.marone3},{IMG : imageDB.marone4},{IMG : imageDB.marone5},{IMG : imageDB.marone6}
    ,{IMG : imageDB.marone7},{IMG : imageDB.marone8},{IMG : imageDB.marone9},{IMG : imageDB.marone10}

  ]);

  const [best5items, setBest5items] = useState([
    {
      IMG : imageDB.connectist1
    },{IMG : imageDB.connectist2},{IMG : imageDB.connectist3},{IMG : imageDB.connectist4},{IMG : imageDB.connectist5},{IMG : imageDB.connectist6}

  ]);
  const [best6items, setBest6items] = useState([
    {
      IMG : imageDB.murray1
    },{IMG : imageDB.murray2},{IMG : imageDB.murray3},{IMG : imageDB.murray4}

  ]);
  const [best7items, setBest7items] = useState([
    {
      IMG : imageDB.sokcho1
    },{IMG : imageDB.sokcho2},{IMG : imageDB.sokcho3},{IMG : imageDB.sokcho4},{IMG : imageDB.sokcho5},{IMG : imageDB.sokcho6}

  ]);
  const [refresh, setRefresh] = useState(1);
  useEffect(()=>{
    async function fetchData(){

    }

    fetchData();
  }, [refresh]);

  return (
    <>
       <div style={{display:"flex",marginTop:20, flexDirection:"row"}}>
        <div style={{display:"flex", height:80, flexDirection:"column",alignItems: "flex-start", width:"50%", alignItems:"flex-start",
      paddingTop:'70px'}}>
          <Label name={'ICT 사업부'} weight={800}/>
          <div style={{height:10}}/>
          <SubLabel name={'ICT 사업부 활동입니다 다양한 분야의 앱 개발 노하우를 보유하고 있습니다 since 2018'}  weight={100} color={'#999'}/>
        </div>
  
       </div>
       <div style={{display:"flex",  flexDirection:"row", flexWrap:"no-wrap", padding:20, justifyContent:"space-around", backgroundColor:"white"}}>
        <Swiper spaceBetween={10} 
        navigation= {
        {   nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',}
        }
          

      slidesPerView={6} loop={false} scrollbar={{ draggable: true }} style={swiperStyle} autoplay={{ delay: 8000, disableOnInteraction: true }} >
          {
            best4items.map((data, index = 0)=>(
              <SwiperSlide key={index}>
              <BuyItem  index={index} img={data.IMG} speed={300}/>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div> 

      <div style={{display:"flex",  flexDirection:"row", flexWrap:"no-wrap", padding:20, justifyContent:"space-around", backgroundColor:"white"}}>
        <Swiper spaceBetween={10} 
        navigation= {
        {   nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',}
        }
          

      slidesPerView={6} loop={false} scrollbar={{ draggable: true }} style={swiperStyle} autoplay={{ delay: 4000, disableOnInteraction: true }} >
          {
            best5items.map((data, index = 0)=>(
              <SwiperSlide key={index}>
              <BuyItem  index={index} img={data.IMG} speed={100}/>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div> 
      <div style={{display:"flex",  flexDirection:"row", flexWrap:"no-wrap", padding:20, justifyContent:"space-around", backgroundColor:"white"}}>
        <Swiper spaceBetween={10} 
        navigation= {
        {   nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',}
        }
          

      slidesPerView={6} loop={false} scrollbar={{ draggable: true }} style={swiperStyle} autoplay={{ delay: 5000, disableOnInteraction: true }} >
          {
            best2items.map((data, index = 0)=>(
              <SwiperSlide key={index}>
              <BuyItem  index={index} img={data.IMG} speed={200}/>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div> 
  
      <div style={{display:"flex",  flexDirection:"row", flexWrap:"no-wrap", padding:20, justifyContent:"space-around", backgroundColor:"white"}}>
        <Swiper spaceBetween={10} 
        navigation= {
        {   nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',}
        }
          

      slidesPerView={3} loop={false} scrollbar={{ draggable: true }} style={swiperStyle} autoplay={{ delay: 8000, disableOnInteraction: true }} >
          {
            best3items.map((data, index = 0)=>(
              <SwiperSlide key={index}>
              <BuyItem2  index={index} img={data.IMG} speed={700}/>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div> 
      <div style={{display:"flex",  flexDirection:"row", flexWrap:"no-wrap", padding:20, justifyContent:"space-around", backgroundColor:"white"}}>
        <Swiper spaceBetween={10} 
        navigation= {
        {   nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',}
        }
          

      slidesPerView={4} loop={false} scrollbar={{ draggable: true }} style={swiperStyle} autoplay={{ delay: 8000, disableOnInteraction: true }} >
          {
            best6items.map((data, index = 0)=>(
              <SwiperSlide key={index}>
              <BuyItem2  index={index} img={data.IMG} speed={700}/>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div> 
      <div style={{display:"flex",  flexDirection:"row", flexWrap:"no-wrap", padding:20, justifyContent:"space-around", backgroundColor:"white"}}>
        <Swiper spaceBetween={10} 
        navigation= {
        {   nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',}
        }
          

      slidesPerView={3} loop={false} scrollbar={{ draggable: true }} style={swiperStyle} autoplay={{ delay: 2000, disableOnInteraction: true }} >
          {
            best7items.map((data, index = 0)=>(
              <SwiperSlide key={index}>
              <BuyItem3  index={index} img={data.IMG} speed={100}/>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div> 
    </>
 

  );
};

export default ICT;
