import React, { useEffect} from "react";
export const imageDB =  {
  logo  : "https://firebasestorage.googleapis.com/v0/b/connectist-cb8bc.appspot.com/o/define%2Ficon.png?alt=media&token=360df8cc-ff8c-4e8e-aac9-e3e32b684aee",
  mainlogo : require('../assets/images/logo.png'),
  calendar :  require('../assets/images/calendar.png'),
  banner : require('../assets/images/banner.png'),
  down : require('../assets/images/down.png'),
  agree : require('../assets/images/agree.png'),
  GlobeOn : require('../assets/images/GlobeOn.png'),
  Taxoff : require('../assets/images/Taxoff.png'),
  NationOff : require('../assets/images/Nationoff.png'),
  logoimage : require('../assets/images/insurancelogo.png'),
  meritzlogo :require('../assets/images/meritzlogo.png'),
  ChevronRight :require('../assets/images/ChevronRight.png'),
  ChevronRight2 :require('../assets/images/ChevronRight2.png'),
  maindisply : require('../assets/images/maindisplay.png'),
  plus : require('../assets/images/home/plus.png'),
  preview1 : require('../assets/images/home/preview1.png'),
  preview2 : require('../assets/images/home/preview2.png'),
  preview3 : require('../assets/images/home/preview3.png'),
  preview4 : require('../assets/images/home/preview4.png'),
  preview5 : require('../assets/images/home/preview5.png'),
  preview6 : require('../assets/images/home/preview6.png'),
  preview7 : require('../assets/images/home/preview7.png'),
  preview8 : require('../assets/images/home/preview8.png'),
  carry : require('../assets/images/home/carry.png'),
  frequent : require('../assets/images/home/frequent.png'),
  delivery : require('../assets/images/home/delivery.png'),
  footer : require('../assets/images/Foot.png'),
  hamburg : require('../assets/images/hamburg.png'),
  check : require('../assets/images/check.png'),
  plusminus : require('../assets/images/plusminus.png'),
  close : require('../assets/images/close.png'),
  uncheck : require('../assets/images/uncheck.png'),
  best1 : require('../assets/images/home/best1.png'),
  best2 : require('../assets/images/home/best2.png'),
  best3 : require('../assets/images/home/best3.png'),
  best4 : require('../assets/images/home/best4.png'),
  best5 : require('../assets/images/home/best5.png'),
  best6 : require('../assets/images/home/best6.png'),
  best7 : require('../assets/images/home/best7.png'),
  best8 : require('../assets/images/home/best8.png'),
  best9 : require('../assets/images/home/best9.png'),
  best10 : require('../assets/images/home/best10.png'),
  news : require('../assets/images/home/news.png'),
  left : require('../assets/images/home/left.png'),
  right : require('../assets/images/home/right.png'),
  head : require('../assets/images/home/head.png'),
  eye : require('../assets/images/home/eye.png'),
  up : require('../assets/images/home/up.png'),
  down : require('../assets/images/home/down.png'),
  basket : require('../assets/images/home/basket.png'),
  guide : require('../assets/images/home/guide.png'),
  bell : require('../assets/images/home/bell.png'),
  heart : require('../assets/images/home/heart.png'),
  search : require('../assets/images/home/search.png'),
  product1 : require('../assets/images/product/product1.png'),
  basketsample : require('../assets/images/product/basketsample.png'),
  producttemplate1 : require('../assets/images/product/producttemplate1.png'),
  producttemplate2 : require('../assets/images/product/producttemplate2.png'),
  producttemplate3 : require('../assets/images/product/producttemplate3.png'),
  banner : require('../assets/images/banner.png'),
  banner2 : require('../assets/images/banner2.png'),
  sample2 : require('../assets/images/product/sample2.png'),
  sample3 : require('../assets/images/product/sample3.png'),
  desktop : require('../assets/images/desktop.png'),
  mobile : require('../assets/images/mobile.png'),
  right : require('../assets/images/right.png'),
  left : require('../assets/images/left.png'),
  ai : require('../assets/images/all.png'),
  pdf : require('../assets/images/zip.png'),
  download : require('../assets/images/download.png'),
  best : require('../assets/images/best.png'),
  mdpick : require('../assets/images/mdpick.png'),
  saletop : require('../assets/images/saletop.png'),
  new : require('../assets/images/new.png'),
  heart : require('../assets/images/heart.png'),
  companion1 : require('../assets/images/product/companion1.png'),
  companion2 : require('../assets/images/product/companion2.png'),
  companion3 : require('../assets/images/product/companion3.png'),
  companion4 : require('../assets/images/product/companion4.png'),
  companion5 : require('../assets/images/product/companion5.png'),
  companionvideo1 : require('../assets/images/product/companion1.MP4'),
  companionvideo2 : require('../assets/images/product/companion2.MP4'),
  buy1 : require('../assets/new/1.png'),
  buy2 : require('../assets/new/2.png'),
  buy3 : require('../assets/new/3.png'),
  buy4 : require('../assets/new/4.png'),
  buy5 : require('../assets/new/5.png'),
  buy6 : require('../assets/new/6.png'),
  buy7 : require('../assets/new/7.png'),
  buy8 : require('../assets/new/8.png'),
  buy9 : require('../assets/new/9.png'),
  buy10 : require('../assets/new/10.png'),
  buy11 : require('../assets/new/11.png'),
  buy12 : require('../assets/new/12.png'),
  buy13 : require('../assets/new/13.png'),
  buy14 : require('../assets/new/14.png'),
  buy15 : require('../assets/new/15.png'),
  buy16 : require('../assets/new/16.png'),
  buy17 : require('../assets/new/17.png'),
  buy18 : require('../assets/new/18.png'),
  buy19 : require('../assets/new/19.png'),
  buy20 : require('../assets/new/20.png'),
  caryou1 : require('../assets/caryou/1.png'),
  caryou2 : require('../assets/caryou/2.png'),
  caryou3 : require('../assets/caryou/3.png'),
  caryou4 : require('../assets/caryou/4.png'),
  caryou5 : require('../assets/caryou/5.png'),
  caryou6 : require('../assets/caryou/6.png'),
  caryou7 : require('../assets/caryou/7.png'),
  caryou8 : require('../assets/caryou/8.png'),
  caryou9 : require('../assets/caryou/9.png'),
  caryou10 : require('../assets/caryou/10.png'),
  order1 : require('../assets/order/1.png'),
  order2 : require('../assets/order/2.png'),
  order3 : require('../assets/order/3.png'),
  order4 : require('../assets/order/4.png'),
  order5 : require('../assets/order/5.png'),
  order6 : require('../assets/order/6.png'),
  order7 : require('../assets/order/7.png'),
  main1 : require('../assets/main/1.png'),
  main2 : require('../assets/main/2.png'),
  main3 : require('../assets/main/3.png'),
  main4 : require('../assets/main/4.png'),
  main5 : require('../assets/main/5.png'),
  main6 : require('../assets/main/6.png'),
  main7 : require('../assets/main/7.png'),
  main8 : require('../assets/main/8.png'),
  marone1 : require('../assets/marone/1.png'),
  marone2 : require('../assets/marone/2.png'),
  marone3 : require('../assets/marone/3.png'),
  marone4 : require('../assets/marone/4.png'),
  marone5 : require('../assets/marone/5.png'),
  marone6 : require('../assets/marone/6.png'),
  marone7 : require('../assets/marone/7.png'),
  marone8 : require('../assets/marone/8.png'),
  marone9 : require('../assets/marone/9.png'),
  marone10 : require('../assets/marone/10.png'),
  connectist1 : require('../assets/connectist/1.png'),
  connectist2 : require('../assets/connectist/2.png'),
  connectist3 : require('../assets/connectist/3.png'),
  connectist4 : require('../assets/connectist/4.png'),
  connectist5 : require('../assets/connectist/5.png'),
  connectist6 : require('../assets/connectist/6.png'),
  murray1 : require('../assets/murray/1.png'),
  murray2 : require('../assets/murray/2.png'),
  murray3 : require('../assets/murray/3.png'),
  murray4 : require('../assets/murray/4.png'),
  sokcho1 : require('../assets/sokcho/1.png'),
  sokcho2 : require('../assets/sokcho/2.png'),
  sokcho3 : require('../assets/sokcho/3.png'),
  sokcho4 : require('../assets/sokcho/4.png'),
  sokcho5 : require('../assets/sokcho/5.png'),
  sokcho6 : require('../assets/sokcho/6.png'),
  sokcho7 : require('../assets/sokcho/7.png'),
  introduce1 : require('../assets/introduce/introduce.png'),
  companion1 : require('../assets/companion/1.png'),
  companion2 : require('../assets/companion/2.png'),
  companion3 : require('../assets/companion/3.png'),
  companion4 : require('../assets/companion/4.png'),
  companion5 : require('../assets/companion/5.png'),
  companion6 : require('../assets/companion/6.png'),
  companion7 : require('../assets/companion/7.png'),
  companion8 : require('../assets/companion/8.png'),
  companion9 : require('../assets/companion/9.png'),
  companion10 : require('../assets/companion/10.png'),
  electron1 : require('../assets/electron/1.png'),
  electron2 : require('../assets/electron/2.png'),
  electron3 : require('../assets/electron/3.png'),
  electron4 : require('../assets/electron/4.png'),
  electron5 : require('../assets/electron/5.png'),
  electron6 : require('../assets/electron/6.png'),
  electron7 : require('../assets/electron/7.png'),
  electron8 : require('../assets/electron/8.png'),
  electron9 : require('../assets/electron/9.png'),
  electron10 : require('../assets/electron/10.png'),
  electronmov1 : require('../assets/electron/1.mp4'),
  electronmov2 : require('../assets/electron/2.mp4'),
  electronmov3 : require('../assets/electron/3.mp4'),
  electronmov4 : require('../assets/electron/4.mp4'),
  banner1 : require('../assets/banner/banner1.png'),
  banner2 : require('../assets/banner/banner2.png'),
  banner3 : require('../assets/banner/banner3.png'),
  banner4 : require('../assets/banner/banner4.png'),
  banner6 : require('../assets/banner/banner6.png'),
}



