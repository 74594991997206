import React, { useEffect, useRef, useState } from 'react';
import { imageDB } from "../components/imageDB";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';

import '../components/css/common.css';

import useDimensions from 'react-use-dimensions'
import Button from '../components/Layout/Button';
import { COLORS } from '../components/Theme';
import Advertise from './component/Advertise';
import Label from './component/Label';
import Preview from './component/Categoryview';
import Noticeview from './component/Noticeview';
import Center from './component/Center';
import Best from './component/Best';
import Pick from './component/Pick';
import News from './component/News';
import Search from '../components/Layout/SearchBasket';
import { registUser } from '../utility/userfirebase';
import { uploadImage } from '../utility/productfirebase';



const Padding = styled.div`
  padding: 0px 10px;
`

const DataView =styled.div`
    border : 1px solid #a0a0a0;
    height : 80%;
    margin : 5px 5px 20px;
`

const Item = styled.div`
  display: flex;
  flex-direction : column;
  width:100%;
  margin-top:40px;
  height:50px;
`

const ItemTitle = styled.div`
  display: flex;
  font-size :16px;
`
const ItemContent = styled.div`
  display: flex;
  width :100%;
  border-bottom :1px solid #e7e7e7;
`

const BtnView = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  padding: 0px 5px;
  margin-bottom : 30px;
`
const AdjustBtn =styled.div`
    height :50px;
    width: 45%;
    border: 1px solid #ededed;
    display:flex;
    flex-direction : row;
    align-items : center;
    justify-content : center;
    border-radius: 5px;
    font-size:18px;
    margin-left:10px;

    background-color :#3f4850;
    color :#fff;
`


const Registercontainer = () => {


  const navigate = useNavigate();

  const [width, setWidth] = useState(100);
  const [ref, setRef] = useState(null);


  useEffect(() => {
   //mount 시에
    console.log("init");

    window.scrollTo(0,0);

    return () => {

      //unmount 시에
      console.log("dispose");
    };
  }, []);

  const _handleregist =async() =>{
      const EMAIL = email;
      const BRAND = brand;
      const BANK = bank;
      const BUSINESSLICENSE = businesslicense;
      const BANKLICENSE = banklicense;
      const STORENAME =storename;
      const CEONAME =ceoname;
      const BUSINESSNUM = businessnum;
      const RANK =rank;
      const TEL = tel;
      const USERID = userid + "@gmail.com";
      const PASSWORD = password;

      const registdata = await registUser({
        USERID, 
        PASSWORD, 
        TEL, RANK, BUSINESSNUM, CEONAME, STORENAME, BANKLICENSE,BUSINESSLICENSE,BANK,BRAND,EMAIL});

    if(registdata == null){
      alert("가입에 실패 하였습니다");
    }else{
      alert("성공적으로 가입 되었습니다");

      navigate("/home");
    }

  }

  const [email, setEmail] = useState('');
  const onemailChange = (e) => {setEmail(e.target.value);}
  const [brand, setBrand] = useState('');
  const onbrandChange = (e) =>{setBrand(e.target.value);}
  const [bank, setBank] = useState('');
  const onbankChange = (e) =>{setBank(e.target.value);}
  const [businesslicense, setBusinesslicense] = useState('');


  const [banklicense, setBanklicense] = useState('');
  const onbanklicenseChnage = (e) =>{setBanklicense(e.target.value);}

  const [storename, setStorename] = useState('');
  const onstorenameChange = (e) =>{setStorename(e.target.value);}

  const [ceoname, setCeoname] = useState('');
  const onceonameChange = (e) =>{setCeoname(e.target.value);}

  const [businessnum, setBusinessnum] = useState('');
  const onbusinessnumChange = (e) =>{setBusinessnum(e.target.value);}

  const [rank, setRank] = useState('');
  const onrankChange = (e) =>{setRank(e.target.value);}
  
  const [tel, setTel] = useState('');
  const ontelChange = (e) =>{setTel(e.target.value);}

  const [userid, setUserid] = useState('');
  const onuseridChange = (e) =>{setUserid(e.target.value);}

  const [password, setPassword] = useState('');
  const onpasswordChange = (e) =>{setPassword(e.target.value);}

  const [businessfilename, setBusinessfilename] = useState('');
  const [bankfilename, setBankfilename] = useState('');

  const fileInput = useRef();
  const fileInput2 = useRef();

  const handleUploadClick = e => {
    fileInput.current.click();
  }
  const handleUploadClick2 = e => {
    fileInput2.current.click();
  }

  const handlefileuploadChange = async(e) => {

    let filename = "";
    const file = e.target.files[0];
    filename = file.name;
    
    if(!ImagefileExtensionValid(filename)){
      window.alert("업로드 대상 파일의 확장자는 bmp, jpg, jpeg, png 만 가능 합니다");
      return;
  }

    var p1 =  new Promise(function(resolve, reject){
  
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {	
      let img = reader.result;
      resolve(img);
    }

  })
  const getRandom = () => Math.random();
  const email = getRandom();


  p1.then(async(result)=>{
    const uri = result;
    console.log("uri", uri);

    const URL = await ImageUpload(uri, email);
    console.log("image", URL);
    setBusinesslicense(URL);
    setBusinessfilename(filename);
  })

  }

  const handlefileuploadChange2 = async(e) => {

    let filename = "";
    const file = e.target.files[0];
    filename = file.name;
    
    if(!ImagefileExtensionValid(filename)){
      window.alert("업로드 대상 파일의 확장자는 bmp, jpg, jpeg, png 만 가능 합니다");
      return;
  }
    var p1 =  new Promise(function(resolve, reject){
    const file = e.target.files[0];
    filename = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {	
      let img = reader.result;
      resolve(img);
    }

  })
  const getRandom = () => Math.random();
  const email = getRandom();


  p1.then(async(result)=>{
    const uri = result;
    console.log("uri", uri);

    const URL = await ImageUpload(uri, email);
    console.log("image", URL);
    setBanklicense(URL);
    setBankfilename(filename);
  })

  }


  const ImageUpload= async(data, data2) =>{
    const uri = data;
    const email = data2;
    const URL = await uploadImage({uri, email});
    return URL;
  }
  const ALLOW_IMAGE_FILE_EXTENSION ="jpg,jpeg,png,bmp";

  const ImagefileExtensionValid = (name) =>{

    const extention = removeFileName(name);

    if(ALLOW_IMAGE_FILE_EXTENSION.indexOf(extention) <= -1 || extention == ''){
        return false;
    }
    return true;

  }
  const removeFileName = (originalFileName)=>{
    const lastIndex = originalFileName.lastIndexOf(".");
    
    if(lastIndex <0){
        return "";
    }
    return originalFileName.substring(lastIndex +1).toLowerCase();
  }

  return (
    <div className ="Container" style={{justifyContent: "center",
      alignItems: "center",padding: '0px 10%'}}>
      <div style={{marginTop:'5%'}}>
        <Label name={'머레이코리아 B2B 셀러 회원가입'} weight={600}/>
        <div style={{display:"flex", flexDirection:"row", padding:20}}>
          <div style={{display:"flex", width:"65%", flexDirection:"column"}}>


            <Item>
              <ItemTitle>아이디</ItemTitle>
              <ItemContent>
                <input type="text" className='InputCommon' value={userid} onChange={onuseridChange}></input>
              </ItemContent>
            </Item>

            <Item>
              <ItemTitle>상호명(회사명)</ItemTitle>
              <ItemContent>
                <input type="text" className='InputCommon' value={storename} onChange={onstorenameChange}></input>
              </ItemContent>
            </Item>

            <Item>
              <ItemTitle>대표자명</ItemTitle>
              <ItemContent>
                <input type="text" className='InputCommon' value={ceoname} onChange={onceonameChange}></input>
              </ItemContent>
            </Item>

            <Item>
              <ItemTitle>사업자 등록번호</ItemTitle>
              <ItemContent>
                <input type="text" className='InputCommon' value={businessnum} onChange={onbusinessnumChange}></input>
              </ItemContent>
            </Item>

            <Item>
              <ItemTitle>담당자명 / 직급</ItemTitle>
              <ItemContent>
                <input type="text" className='InputCommon' value={rank} onChange={onrankChange}></input>
              </ItemContent>
            </Item>

            <Item>
              <ItemTitle>담당자 연락처</ItemTitle>
              <ItemContent>
                <input type="text" className='InputCommon' value={tel} onChange={ontelChange}></input>
              </ItemContent>
            </Item>
            
          </div>
          <div style={{display:"flex",  width:"65%", flexDirection:"column", marginLeft:100}}>


            <Item>
              <ItemTitle>패스워드</ItemTitle>
              <ItemContent>
                <input type="text" className='InputCommon' value={password} onChange={onpasswordChange}></input>
              </ItemContent>
            </Item>

            <Item>
              <ItemTitle>담당자 E-mail</ItemTitle>
              <ItemContent>
                <input type="text" className='InputCommon' value={email} onChange={onemailChange}></input>
              </ItemContent>
            </Item>

            <Item>
              <ItemTitle>브랜드명</ItemTitle>
              <ItemContent>
                <input type="text" className='InputCommon' value={brand} onChange={onbrandChange}></input>
              </ItemContent>
            </Item>

            <Item>
              <ItemTitle>은행계좌</ItemTitle>
              <ItemContent>
                <input type="text" className='InputCommon' value={bank} onChange={onbankChange}></input>
              </ItemContent>
            </Item>

            <Item>
              <ItemTitle>사업자 등록증 파일 첨부(bmp, jpg, jpeg, png만 가능)</ItemTitle>
              <ItemContent>
                <div style={{borderBottom: "1px solid #ededed", 
                display:"flex", alignItems:"flex-end",
                height:35, width:"100%"}} onClick={handleUploadClick}>{businessfilename}</div>
                <input type="file"  ref={fileInput} 
                  onChange={handlefileuploadChange}
                  style={{ display: "none" }} />
              </ItemContent>
            </Item>

            <Item>
              <ItemTitle>통장사본 파일 첨부(bmp, jpg, jpeg, png만 가능)</ItemTitle>
              <ItemContent>
              <div style={{borderBottom: "1px solid #ededed", 
                display:"flex", alignItems:"flex-end",
                height:35, width:"100%"}} onClick={handleUploadClick2}>{bankfilename}</div>
                <input type="file"  ref={fileInput2} 
                  onChange={handlefileuploadChange2}
                  style={{ display: "none" }} />
              </ItemContent>
            </Item>


          </div>


        </div>
        <div>
            <img src={imageDB.agree} style={{width:"90%", margin:"50px 0px"}}/>
        </div>

        <BtnView>
          <AdjustBtn onClick={_handleregist}>가입</AdjustBtn>
        </BtnView>

      </div>
    </div>
  );
}

export default Registercontainer;
