import React,{useState, useEffect, useContext} from 'react';

import Layout from '../components/Layout/Layout';
import Categoryproductcontainer from '../container/Categoryproductcontainer';
import CategoryLayout from '../components/Layout/CategoryLayout';
import { CategoryContext } from '../context/Category';
import { getproduct } from '../utility/productfirebase';
import { useLocation } from 'react-router-dom';
import { Define } from '../components/Define';
import Introducecontainer from '../container/Introducecontainer';
import Homelayout from '../components/Layout/Homelayout';
import Companioncontainer from '../container/Companioncontainer';


const Companionpage = ({pagetype}) => {




  return (

    <Homelayout type={pagetype}>
    <Companioncontainer />
    </Homelayout>
  );
}

export default Companionpage;
