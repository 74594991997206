import firebase from "firebase/compat/app";

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";


const firebaseConfig = {
    apiKey: "AIzaSyCwb3ZamjEwUD11aTwwXmV0hpzDxSAwo6w",
    authDomain: "educom-db3fa.firebaseapp.com",
    databaseURL: "https://educom-db3fa-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "educom-db3fa",
    storageBucket: "educom-db3fa.appspot.com",
    messagingSenderId: "965291167889",
    appId: "1:965291167889:web:f387abe1a70e3799686f30",
    measurementId: "G-WZ1T06F64R"
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };