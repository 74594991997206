

import React, { Fragment, useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import './BestItem.css'
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import { CommaFormatted } from "../../utility/common";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Layout/Button";


const Container = styled.div`
  border-radius: 5px;
  width:19%;
  flex-direction:column;
  background-color: #fff;
  margin-right:10px;
  margin-bottom:40px;
  display: flex;
  border-radius:10px;
`

const CheckItem = styled.div`
  background-color: #b5b5b7c9;

  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`

const UnCheckItem = styled.div`

  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`
const OrderItem = ({img, name, price}) => {

  const navigate = useNavigate();

  const [select , setSelect] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const _handleMouseOver = () =>{
    if(isHovering){
      return;
    }
    setIsHovering(true); 
    console.log("mouse over");
  }
  const _handleMouseOut= () =>{

    if(!isHovering){
      return;
    }
    setIsHovering(false);
    console.log("mouse out");
  
  }
  const allselect = () =>{
    setSelect(!select);
  }


  const _handleProduct = () =>{

    navigate("/product");
  }

  const _handleMinus =()=>{
    if(amount ==0){
      return;
    }
    let count = amount;
    count = count -1;
    setAmount(count);
  }
  const _handlePlus =()=>{

    let count = amount;
    count = count +1;
    setAmount(count);
  }

  return (

    <Fragment>

       

          <div style={{display:"flex", padding:"50px 10px 20px 30px", flexDirection:"column"}}>

            <div style={{height:"300px", width:"100%",
              padding: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'flex-start',
              border: '1px solid #ededed'
              }}>
                <div>배송지</div>
                <div>경기도 안성시 중앙로 327 산학협력관 504호</div>
                <Button Label={'배송지 변경하기'} bgcolor={'#fff'}
                borderRadius={10}
                color={'#000'} bordercolor={'#000'} height={40}/>
                <div>전체상품</div>
                <div>주문금액</div>
                <div>배송비</div>
                <div>최종결재금액</div>
         
            </div>

            <div style={{display:"flex", justifyContent:"center", alignItems:"cetner"}}>
              <Button Label={'주문하기'} bgcolor={'#000'}
                borderRadius={10}
                color={'#fff'} bordercolor={'#000'} height={40}/>
            </div>
         
          </div>
  
     

    </Fragment>

  
  );
};

OrderItem.defaultProps={
  price : 0
}
export default OrderItem;
