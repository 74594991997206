

import React, { useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import Preview from "./Categoryview";
import Noticeview from "./Noticeview";
import BestItem from "./BestItem";
import Page from "../../components/Layout/Page";
import { getBest } from "../../utility/bestfirebase";
import SubLabel from "./SubLabel";
import Fade from 'react-reveal/Fade';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { getMd } from "../../utility/mdfirebase";
import Nav from '../Nav';
import DataItem from "./DataItem";
import News from "./News";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y,Autoplay]); // *


const swiperStyle={
  position :"relative",
  width :'100%',
}

const Companion = () => {

  const [bestitems, setBestitems] = useState([
    {
      IMG : imageDB.companion1
    },
    {
      IMG : imageDB.companion2
    },
    {
      IMG : imageDB.companion3
    },
    {
      IMG : imageDB.companion4
    },
    {
      IMG : imageDB.companion5
    }
  ]);
  const [refresh, setRefresh] = useState(1);
  useEffect(()=>{
    async function fetchData(){

    }

    fetchData();
  }, [refresh]);

  return (
    <div style={{display:"flex",marginTop:20, flexDirection:"column"}}>
      <div style={{display:"flex",marginTop:20, flexDirection:"row"}}>
        <div style={{display:"flex", height:80, flexDirection:"column",alignItems: "flex-start", width:"20%", alignItems:"flex-start",
      paddingTop:'70px'}}>
          <Label name={'반려동물용품'} weight={800}/>
          <div style={{height:10}}/>
          <SubLabel name={'반려동물용품 사업부입니다'}  weight={100} color={'#999'}/>
        </div>



        <div style={{width:"80%"}}>
        <div style={{display:"flex",  flexDirection:"row", flexWrap:"no-wrap", padding:"20px 20px 0px 20px", justifyContent:"space-around", backgroundColor:"white"}}>
            <Swiper spaceBetween={10} 
            navigation= {
            {   nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',}
            }
              

            slidesPerView={3} loop={false} scrollbar={{ draggable: true }} style={swiperStyle} autoplay={{ delay: 5000, disableOnInteraction: true }} >
              {
                bestitems.map((data, index = 0)=>(
                  <SwiperSlide key={index}>
                    <DataItem index ={index} img={data.IMG} />
                  </SwiperSlide>
                ))
              }
            </Swiper>


        </div>




        </div>


      
      </div>
      <div style={{display:"flex", flexDirection:"row",justifyContent:"space-around"}}>
        <video  width="500px" height="400px"  controls autoPlay muted>
              <source src={imageDB.companionvideo1} type="video/mp4" />
        </video>
        <video  width="500px" height="400px"  controls autoPlay muted>
              <source src={imageDB.companionvideo2} type="video/mp4" />
        </video>

      </div>
    </div>

  );
};

export default Companion;
