import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes } from "react-router-dom";
import Homepage from "./screen/Homepage";
import Productpage from "./screen/Productpage";
import Registerpage from "./screen/Registerpage";
import Basketpage from "./screen/Basketpage";
import Categoryproductpage from "./screen/Categoryproductpage";
import MemberLoginpage from "./screen/MemberLoginpage";
import MemberRegisterpage from "./screen/MemberRegisterpage";
import Itemproductpage from "./screen/Itemproductpage";
import { Define } from "./components/Define";
import Heartproductpage from "./screen/Heartproductpage";
import Introducepage from "./screen/Introducepage";
import Companionpage from "./screen/Companionpage";
import Electronpage from "./screen/Electronpage";
import VariVaripage from "./screen/VariVaripage";
import Portfoliopage from "./screen/Portfoliopage";



const App =() =>  {
  return (
  
        <Routes>
            <Route path="/" element={<Homepage/>}/>
            <Route path="/memberlogin" element={<MemberLoginpage/>}/>
            <Route path="/home" element={<Homepage/>}/>
            <Route path="/product" element={<Productpage/>}/>
            <Route path="/memberregister" element={<MemberRegisterpage/>}/>
            <Route path="/basket" element={<Basketpage/>}/>
            <Route path="/categoryproduct" element={<Itemproductpage pagetype={Define.allcategory}/>}/>
            <Route path="/introduce" element={<Introducepage pagetype={Define.allcategory}/>}/>
            <Route path="/portfolio" element={<Portfoliopage pagetype={Define.allcategory}/>}/>
            <Route path="/companion" element={<Companionpage pagetype={Define.allcategory}/>}/>
            <Route path="/electron" element={<Electronpage pagetype={Define.allcategory}/>}/>
            <Route path="/varivari" element={<VariVaripage pagetype={Define.allcategory}/>}/>
            <Route path="/bestproduct" element={<Itemproductpage pagetype={Define.best}/>}/>
            <Route path="/newproduct" element={<Itemproductpage pagetype={Define.new}/>}/>
            <Route path="/mdproduct" element={<Itemproductpage pagetype={Define.md}/>}/>
            <Route path="/marginproduct" element={<Itemproductpage pagetype={Define.gomargin}/>}/>
            <Route path="/topproduct" element={<Itemproductpage pagetype={Define.top}/>}/>
            <Route path="/soldoutproduct" element={<Itemproductpage pagetype={Define.soldout}/>}/>
            <Route path="/outproduct" element={<Itemproductpage pagetype={Define.out}/>}/>
            <Route path="/heartproduct" element={<Heartproductpage pagetype={Define.heart}/>}/>
        </Routes>  
  

  )

 
}

export default App;
