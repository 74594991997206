import React, { useEffect, useState } from 'react';
import { imageDB } from "../components/imageDB";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate, useLocation} from "react-router-dom";
import styled from 'styled-components';

import '../components/css/common.css';

import useDimensions from 'react-use-dimensions'
import Button from '../components/Layout/Button';
import { COLORS } from '../components/Theme';
import Advertise from './component/Advertise';
import Label from './component/Label';
import Preview from './component/Categoryview';
import Noticeview from './component/Noticeview';
import Center from './component/Center';
import Best from './component/Best';
import Pick from './component/Pick';
import News from './component/News';
import Search from '../components/Layout/SearchBasket';
import With from './component/With';
import { getproductbyid } from '../utility/productfirebase';
import { CommaFormatted } from '../utility/common';
import ProductRightside from '../components/Layout/ProductRightside/ProductRightside';
import Fade from 'react-reveal/Fade';
import { MaxPC, SmallPC } from '../components/Responsive';


const Padding = styled.div`
  padding: 0px 10px;
`

const DataView =styled.div`
    border : 1px solid #a0a0a0;
    height : 80%;
    margin : 5px 5px 20px;
`


const Productcontainer = ({PRODUCT_ID}) => {
  const navigate = useNavigate();
  const [width, setWidth] = useState(100);
  const [product, setProduct] = useState({});



  const [saleprice, setSaleprice] = useState('');
  const onsalepricechange =(e)=>{
    const value = e.target.value;
    const removedCommaValue = Number(value.replaceAll(",", ""));
    setSaleprice(removedCommaValue.toLocaleString());
 }
  const [salefee, setSalefee] = useState('');
  const onsalefeechange =(e)=>{
    const value = e.target.value;
    const removedCommaValue = Number(value.replaceAll(",", ""));
    setSalefee(removedCommaValue.toLocaleString())
 }

  const [deliveryfee, setDeliveryfee] = useState('');
  const ondeliveryfeechange =(e)=>{
    const value = e.target.value;
    const removedCommaValue = Number(value.replaceAll(",", ""));
    setDeliveryfee(removedCommaValue.toLocaleString())
 }

  const [margin, setMargin] = useState('');


  const _handleMarginCalculate = () =>{


    let saleconvert = Number(saleprice.replaceAll(",", ""));
    let supplyconvert = Number(product.PRODUCTSUPPLYPRICE.replaceAll(",", ""));

    let percent = (saleconvert - supplyconvert) / saleconvert * 100;
    setMargin(percent + "%");
  }

  const [refresh, setRefresh] = useState(false);
  console.log("product", PRODUCT_ID);

  useEffect(()=>{
    async function fetchData(){
        const product = await getproductbyid(PRODUCT_ID);
        console.log("product info", product);
        setProduct(product);
        setRefresh(true);
    }
    fetchData();
  }, [])

  return (
  
    <>
      <MaxPC>
        <div className ="MaxProductContainer">
      
      {
          refresh == true &&    
          <>
          <div style={{display:'flex', flexDirection:"row", height:630, padding: "20px 10% 20px 10%"}}>
              <div style={{display:'flex',width:'450px', flexDirection:"column"}}>
              <div style={{display:"flex", height:'380px',}} className='MainImg'>
                  <img src={product.PRODUCTIMAGEARY[0]} style={{height:'370px', position:"absolute", width:"450px" }} />
              </div>
              {
                  <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
                      {
                        product.PRODUCTIMAGEARY.map((data, index)=>(
                          <Fade left delay={1000}>
                          <div style={{display:"flex", height:"20%", flexDirection:"row", marginTop:20, marginLeft:10}}>
                          <img src={data} style={{width:'100px', height:'100px', borderRadius:10,}} />
                          </div>
                          </Fade>
                        ))  
                      }
                  </div>
              }
          
              </div>
              <div style={{display:'flex', width:'35%', flexDirection:"column"}}>
              <div style={{display:"flex",flexDirection:"column", height:'50%', backgroundColor:'#fff', border:"1px solid #E1E1E1",margin:'0px 10px 10px 10px', borderRadius:5,padding: '15px 0px 15px 20px'}}>

                <div style={{display:"flex", flexDirection:"row", flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{ width:'30%', color :"#929292"}}>상품명</div>
                    <div style={{ width:'70%'}}>{product.PRODUCTNAME}</div>
                </div>
                <div style={{border: '0.5px solid #DADADA', marginLeft:25,marginBottom:10}}></div>
                <div style={{display:"flex", flexDirection:"row",flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{width:'30%',color :"#929292"}}>모델명</div>
                    <div style={{ width:'70%'}}>{product.PRODUCTMODELNAME}</div>
                </div>

                <div style={{border: '0.5px solid #DADADA', marginLeft:25,marginBottom:10}}></div>
                <div style={{display:"flex", flexDirection:"row", flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{width:'30%',color :"#929292"}}>정상가</div>
                    <div style={{width:'70%'}}>{CommaFormatted(product.PRODUCTREGULARPRICE)}원</div>
                </div>
                <div style={{border: '0.5px solid #DADADA', marginLeft:25,marginBottom:10}}></div>
                <div style={{display:"flex", flexDirection:"column", flexGrow:2, marginBottom:10,alignItems:'center',justifyContent: "center",
                  paddingLeft: 20}}>
                    <div style={{backgroundColor:"#FF6868", color :"#fff", padding: '5px 20px'}} >온라인최저가  {CommaFormatted(product.PRODUCTLOWPRICE)}원</div>
                    <div style={{color:"#FF6868", marginTop:20}}>
                    온라인 최저가는 반드시 지켜주셔야합니다.
                    </div>
                  
                </div>
                <div style={{border: '0.5px solid #DADADA', marginLeft:25,marginBottom:10}}></div>
                <div style={{display:"flex", flexGrow:1, alignItems:'center'}}>

                    <div style={{width:'30%',color :"#929292"}}>파트너공급가</div>
                    <div style={{width:'70%'}}>{CommaFormatted(product.PRODUCTSUPPLYPRICE)}원</div>
      
                </div>
              </div>
              <div style={{display:"flex",flexDirection:"column", height:'60%', marginTop:20,  backgroundColor:'#fff', border:"1px solid #E1E1E1", 
              margin:'0px 10px',borderTopRightRadius: 10,borderTopLeftRadius:10}}>

                <div style={{display:"flex", backgroundColor:"#323232", color:"#fff", flexDirection:"row",height: 50,
                    marginBottom:10, alignItems:'center'}}>
                    <div style={{paddingLeft:20}}>마진 계산기</div>
      
                </div>

                <div style={{display:"flex", flexDirection:"row",flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{width:'30%',color :"#323232"}}>판매가</div>
                    <div style={{ width:'70%'}}><input type="text"  onChange={onsalepricechange}  value={saleprice} placeholder='판매가를 입력해주세요' style={{backgroundColor: "white",
                    border: '1px solid #DADADA',width: '80%'}}/></div>
                </div>


                <div style={{display:"flex", flexDirection:"row",flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{width:'30%',color :"#323232"}}>공급가</div>
                    <div style={{ width:'70%'}}><input type="text"  disabled value={CommaFormatted(product.PRODUCTSUPPLYPRICE)}  placeholder='공급가를 입력해주세요' style={{backgroundColor: "white",
                    border: '1px solid #DADADA',width: '80%', background:"#ededed"}}/></div>
                </div>

                <div style={{display:"flex", flexDirection:"row",flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{width:'30%',color :"#323232"}}>수수료</div>
                    <div style={{ width:'70%'}}><input type="text"  onChange={onsalefeechange} value={salefee} placeholder='판매처 수수료를 입력해주세요' style={{backgroundColor: "white",
                    border: '1px solid #DADADA',width: '80%'}}/></div>
                </div>

                <div style={{display:"flex", flexDirection:"row",flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{width:'30%',color :"#323232"}}>배송비</div>
                    <div style={{ width:'70%'}}><input onChange={ondeliveryfeechange}
                    value={deliveryfee} type="text" placeholder='배송비를 입력해주세요' style={{backgroundColor: "white",
                    border: '1px solid #DADADA',width: '80%'}}/></div>
                </div>
                <div onClick={_handleMarginCalculate} style={{display:"flex", flexDirection:"row",flexGrow:1, marginBottom:10, alignItems:'center', justifyContent:"flex-end"}}>
                      <div style={{backgroundColor:"#689fff", color :"#fff", padding: '5px 20px', marginRight:20}} >마진계산</div>
                </div>

                <div style={{display:"flex", flexDirection:"row",flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{width:'30%',color :"#323232"}}>개당 판매파진</div>
                    <div style={{ width:'70%'}}><input type="text" disabled value={margin} style={{backgroundColor: "white",
                    border: '1px solid #DADADA',width: '80%',background:"#ededed"}}/></div>
                </div>

        
            </div>
              </div>

              <ProductRightside product_id={PRODUCT_ID}/>
          </div>

            <With product={product}/>


          </>    

      }


        </div>
      </MaxPC>
      <SmallPC>
        <div className ="ProductContainer">
      
      {
          refresh == true &&    
          <>
          <div style={{display:'flex', flexDirection:"row", height:630, padding: "20px 10% 20px 10%"}}>
              <div style={{display:'flex',width:'40%', flexDirection:"column"}}>
              <div style={{display:"flex", height:'380px',}} className='MainImg'>
                  <img src={product.PRODUCTIMAGEARY[0]} style={{height:'370px', position:"absolute", width:"32%" }} />
              </div>
              {
                  <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
                      {
                        product.PRODUCTIMAGEARY.map((data, index)=>(
                          <Fade left delay={1000}>
                          <div style={{display:"flex", height:"20%", flexDirection:"row", marginTop:20, marginLeft:10}}>
                          <img src={data} style={{width:'100px', height:'100px', borderRadius:10,}} />
                          </div>
                          </Fade>
                        ))  
                      }
                  </div>
              }
          
              </div>
              <div style={{display:'flex', width:'35%', flexDirection:"column"}}>
              <div style={{display:"flex",flexDirection:"column", height:'50%', backgroundColor:'#fff', border:"1px solid #E1E1E1",margin:'0px 10px 10px 10px', borderRadius:5,padding: '15px 0px 15px 20px'}}>

                <div style={{display:"flex", flexDirection:"row", flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{ width:'30%', color :"#929292"}}>상품명</div>
                    <div style={{ width:'70%'}}>{product.PRODUCTNAME}</div>
                </div>
                <div style={{border: '0.5px solid #DADADA', marginLeft:25,marginBottom:10}}></div>
                <div style={{display:"flex", flexDirection:"row",flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{width:'30%',color :"#929292"}}>모델명</div>
                    <div style={{ width:'70%'}}>{product.PRODUCTMODELNAME}</div>
                </div>

                <div style={{border: '0.5px solid #DADADA', marginLeft:25,marginBottom:10}}></div>
                <div style={{display:"flex", flexDirection:"row", flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{width:'30%',color :"#929292"}}>정상가</div>
                    <div style={{width:'70%'}}>{CommaFormatted(product.PRODUCTREGULARPRICE)}원</div>
                </div>
                <div style={{border: '0.5px solid #DADADA', marginLeft:25,marginBottom:10}}></div>
                <div style={{display:"flex", flexDirection:"column", flexGrow:2, marginBottom:10,alignItems:'center',justifyContent: "center",
                  paddingLeft: 20}}>
                    <div style={{backgroundColor:"#FF6868", color :"#fff", padding: '5px 20px'}} >온라인최저가  {CommaFormatted(product.PRODUCTLOWPRICE)}원</div>
                    <div style={{color:"#FF6868", marginTop:20}}>
                    온라인 최저가는 반드시 지켜주셔야합니다.
                    </div>
                  
                </div>
                <div style={{border: '0.5px solid #DADADA', marginLeft:25,marginBottom:10}}></div>
                <div style={{display:"flex", flexGrow:1, alignItems:'center'}}>

                    <div style={{width:'30%',color :"#929292"}}>파트너공급가</div>
                    <div style={{width:'70%'}}>{CommaFormatted(product.PRODUCTSUPPLYPRICE)}원</div>
      
                </div>
              </div>
              <div style={{display:"flex",flexDirection:"column", height:'60%', marginTop:20,  backgroundColor:'#fff', border:"1px solid #E1E1E1", 
              margin:'0px 10px',borderTopRightRadius: 10,borderTopLeftRadius:10}}>

                <div style={{display:"flex", backgroundColor:"#323232", color:"#fff", flexDirection:"row",height: 50,
                    marginBottom:10, alignItems:'center'}}>
                    <div style={{paddingLeft:20}}>마진 계산기</div>
      
                </div>

                <div style={{display:"flex", flexDirection:"row",flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{width:'30%',color :"#323232"}}>판매가</div>
                    <div style={{ width:'70%'}}><input type="text"  onChange={onsalepricechange}  value={saleprice} placeholder='판매가를 입력해주세요' style={{backgroundColor: "white",
                    border: '1px solid #DADADA',width: '80%'}}/></div>
                </div>


                <div style={{display:"flex", flexDirection:"row",flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{width:'30%',color :"#323232"}}>공급가</div>
                    <div style={{ width:'70%'}}><input type="text"  disabled value={CommaFormatted(product.PRODUCTSUPPLYPRICE)}  placeholder='공급가를 입력해주세요' style={{backgroundColor: "white",
                    border: '1px solid #DADADA',width: '80%', background:"#ededed"}}/></div>
                </div>

                <div style={{display:"flex", flexDirection:"row",flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{width:'30%',color :"#323232"}}>수수료</div>
                    <div style={{ width:'70%'}}><input type="text"  onChange={onsalefeechange} value={salefee} placeholder='판매처 수수료를 입력해주세요' style={{backgroundColor: "white",
                    border: '1px solid #DADADA',width: '80%'}}/></div>
                </div>

                <div style={{display:"flex", flexDirection:"row",flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{width:'30%',color :"#323232"}}>배송비</div>
                    <div style={{ width:'70%'}}><input onChange={ondeliveryfeechange}
                    value={deliveryfee} type="text" placeholder='배송비를 입력해주세요' style={{backgroundColor: "white",
                    border: '1px solid #DADADA',width: '80%'}}/></div>
                </div>
                <div onClick={_handleMarginCalculate} style={{display:"flex", flexDirection:"row",flexGrow:1, marginBottom:10, alignItems:'center', justifyContent:"flex-end"}}>
                      <div style={{backgroundColor:"#689fff", color :"#fff", padding: '5px 20px', marginRight:20}} >마진계산</div>
                </div>

                <div style={{display:"flex", flexDirection:"row",flexGrow:1, marginBottom:10, alignItems:'center'}}>
                    <div style={{width:'30%',color :"#323232"}}>개당 판매파진</div>
                    <div style={{ width:'70%'}}><input type="text" disabled value={margin} style={{backgroundColor: "white",
                    border: '1px solid #DADADA',width: '80%',background:"#ededed"}}/></div>
                </div>

        
            </div>
              </div>

              <ProductRightside product_id={PRODUCT_ID}/>
          </div>

            <With product={product}/>


          </>    

      }


        </div>
      </SmallPC>
    </>

  );
}

export default Productcontainer;
