import React,{useState, useEffect, useContext} from 'react';

import Layout from '../components/Layout/Layout';
import Categoryproductcontainer from '../container/Categoryproductcontainer';
import CategoryLayout from '../components/Layout/CategoryLayout';
import { CategoryContext } from '../context/Category';
import { getproduct } from '../utility/productfirebase';
import { useLocation } from 'react-router-dom';
import { Define } from '../components/Define';

import Homelayout from '../components/Layout/Homelayout';
import Portfoliocontainer from '../container/Portfoliocontainer';


const Portfoliopage = ({pagetype}) => {




  return (

    <Homelayout type={pagetype}>
    <Portfoliocontainer />
    </Homelayout>
  );
}

export default Portfoliopage;
