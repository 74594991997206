

import React, { useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import Preview from "./Categoryview";
import Noticeview from "./Noticeview";
import BestItem from "./BestItem";
import Page from "../../components/Layout/Page";
import { getBest } from "../../utility/bestfirebase";
import SubLabel from "./SubLabel";
import Fade from 'react-reveal/Fade';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { getMd } from "../../utility/mdfirebase";
import Nav from '../Nav';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y,Autoplay]); // *


const swiperStyle={
  position :"relative",
  width :'100%',
}

const NewProduct = () => {

  const [bestitems, setBestitems] = useState([]);
  const [refresh, setRefresh] = useState(1);
  useEffect(()=>{
    async function fetchData(){
      let bestitems = [];
      bestitems = await getMd();

      setBestitems(bestitems);

      console.log("bestitems", bestitems);
    }

    fetchData();
  }, [refresh]);

  return (
    <div style={{display:"flex",marginTop:20, flexDirection:"row"}}>
      <div style={{display:"flex", height:80, flexDirection:"column",alignItems: "flex-start", width:"20%", alignItems:"flex-start",
    paddingTop:'70px'}}>
        <Label name={'신상품'} weight={800}/>
        <div style={{height:10}}/>
        <SubLabel name={'신규 출시한 따끈따끈한 상품입니다'}  weight={100} color={'#999'}/>
      </div>



      <div style={{height:"440px",width:"80%"}}>
      <div style={{display:"flex",  flexDirection:"row", flexWrap:"no-wrap", padding:"20px 20px 0px 20px", justifyContent:"space-around", backgroundColor:"white"}}>
          <Swiper spaceBetween={10} 
          navigation= {
          {   nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',}
          }
            

          slidesPerView={3} loop={false} scrollbar={{ draggable: true }} style={swiperStyle} autoplay={{ delay: 5000, disableOnInteraction: true }} >
            {
              bestitems.map((data, index = 0)=>(
                <SwiperSlide key={index}>
                <BestItem  time={index} product_id ={data.PRODUCT_ID} img={data.PRODUCTIMG} name={data.PRODUCTNAME} select= {data.PRODUCTSELECT}
                price={data.PRODUCTREGULARPRICE} lowprice = {data.PRODUCTLOWPRICE} component ={data.PRODUCTCOMPONENT} add={data.PRODUCT_ADD11_INFO}/>
                </SwiperSlide>
              ))
            }
          </Swiper>


      </div>


      </div>
    
    </div>
  );
};

export default NewProduct;
