import React from 'react';
import Homecontainer from '../container/Homecontainer';
import Layout from '../components/Layout/Layout';

import ProductLayout from '../components/Layout/ProductLayout';
import Registercontainer from '../container/Registercontainer';
import MemberLayout from '../components/Layout/MemberLayout';

const MemberRegisterpage = () => {
  return (
    <MemberLayout>
      <Registercontainer/>
    </MemberLayout>

  );
}

export default MemberRegisterpage;
