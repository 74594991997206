

import React, { Fragment, useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import './BestItem.css'
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import { CommaFormatted } from "../../utility/common";
import { useNavigate } from "react-router-dom";


const Container = styled.div`
  border-radius: 5px;
  width:19%;
  height:260px;
  flex-direction:column;
  background-color: #fff;
  margin-right:10px;
  margin-bottom:40px;
  display: flex;
  border-radius:10px;
`

const CheckItem = styled.div`
  background-color: #b5b5b7c9;

  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`

const UnCheckItem = styled.div`

  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`

const Productname = styled.div`
  display: flex;
    justify-content: flex-start;
    text-align: left;
`
const ProductItem = ({image, name, modelname, price, count}) => {

  const navigate = useNavigate();

  const [select , setSelect] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const _handleMouseOver = () =>{
    if(isHovering){
      return;
    }
    setIsHovering(true); 
    console.log("mouse over");
  }
  const _handleMouseOut= () =>{

    if(!isHovering){
      return;
    }
    setIsHovering(false);
    console.log("mouse out");
  
  }
  const allselect = () =>{
    setSelect(!select);
  }


  const _handleProduct = () =>{

    navigate("/product");
  }

  const _handleMinus =()=>{
    if(amount ==0){
      return;
    }
    let count = amount;
    count = count -1;
    setAmount(count);
  }
  const _handlePlus =()=>{

    let count = amount;
    count = count +1;
    setAmount(count);
  }

  return (

    <Fragment>

          <div style={{display:"flex",borderBottom:"1px solid #ededed", marginLeft:10, height:150}}>

              <div style={{display:"flex", flexDirection:"row", height:'100%', width:"100%" }}>
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", width:'5%'}}>
                  {
                          select == true ? (<img onClick={allselect}  src={imageDB.check} style={{width:"25px", height:"25px"}}/>) :
                          (<img  onClick={allselect} src={imageDB.uncheck} style={{width:"25px", height:"25px"}}/>)
                   }
                  </div>
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center", width:'20%'}}>
                    <img  onClick={allselect} src={image} style={{width:"80%", height:"60%"}}/>
                  </div>
                  <div style={{display:"flex", flexDirection:"column" , width:'40%',justifyContent: "space-evenly",alignItems: "flex-start"
                    ,padding: '20px 0px', borderRight: "1px solid #ededed"}}>
                    <Productname>상품명 : {name}</Productname>
                    <div>모델명 : {modelname}</div>
                    <div>옵션 : 삼성전용 화이트</div>
                  </div>
                  <div style={{display:"flex", flexDirection:"column", width:'30%', justifyContent:"center", alignItems:"center", borderRight: "1px solid #ededed"}}>
                    <div onClick={_handleMinus}style={{position:"relative",top: 25,left: -40}}>-</div>
                    <img src={imageDB.plusminus} style={{width:"70%"}}/>
                    <div style={{position:"relative", top:10,top: -28,}}>{count}</div>
                    <div onClick={_handlePlus}style={{position:"relative", top:10,top: -48,left: 40}}>+</div>
                  </div>
                  <div style={{display:"flex", flexDirection:"column" , width:'30%',justifyContent: "space-around",padding: "20px 0px"}}>

                   <div>
                    <div>상품공급가</div>
                    <div>{CommaFormatted(price)}원</div>
                   </div>

                   <div>
                    <div>배송비</div>
                    <div>3,000원</div>
                  </div>
       
          
                  </div>

                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", width:'5%'}}>
                    <img   src={imageDB.close} style={{width:"25px", height:"25px"}}/>
                  </div>
              </div>
          

          </div>

     

    </Fragment>

  
  );
};

ProductItem.defaultProps={
  price : 0
}
export default ProductItem;
