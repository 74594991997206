

import React, { Fragment, useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import './BestItem.css'
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import { CommaFormatted, ratevalue } from "../../utility/common";
import { useNavigate } from "react-router-dom";
import Fade from 'react-reveal/Fade';

const Container = styled.div`
  border-radius: 5px;
  flex-direction:row;
  margin-right:10px;
  display: flex;
  border-radius:10px;
`

const CheckItem = styled.div`
  background-color: #b5b5b7c9;
  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`

const UnCheckItem = styled.div`

  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`
const Sticker1 = styled.div`
  background-color: #ff00007d;
  color: #fff;
  font-size: 12px;
  top: -8px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 30px;
`
const ProductInfo = styled.div`

  padding: 15px 0px;
  border-top: none;
  width:100%;

`
const ProductName = styled.div`
  font-size: 18px;
  text-align: left;
  padding-left: 10px;
  color: #000;
  font-weight:900;
  font-family: Pretendard-Bold;
`

const ProductPrice = styled.span`
  font-size: 14px;
  text-align: left;
  color: #000;
  font-weight:900;
  font-family: Pretendard-Bold;
`

const ProductRate = styled.span`
  font-size: 16px;
  text-align: left;
  color: #ff0000;
  font-weight:900;
  font-family: Pretendard-Bold;
`
const ProductSubPrice = styled.div`
  font-size: 12px;
  text-align: left;
  color: #929292;
  text-decoration:line-through;
`
const PickItem = ({product_id, img, name, price,lowprice, component,add ,select, time, index}) => {

  const navigate = useNavigate();

  const [isHovering, setIsHovering] = useState(false);
  const _handleMouseOver = () =>{
    if(isHovering){
      return;
    }
    setIsHovering(true); 
    console.log("mouse over");
  }
  const _handleMouseOut= () =>{

    if(!isHovering){
      return;
    }
    setIsHovering(false);
    console.log("mouse out");
  
  }


  const _handleProduct = () =>{
    navigate("/product", {state : {PRODUCT_ID: product_id}});
  }

  const existselect = (selectname)=>{
    let selectlist = select;

    const FindIndex = selectlist.findIndex(x=>x == selectname);
  
    if(FindIndex == -1){
      return false;
    }else{
      return true;
    }

  }

  return (

    <Fragment>
        <Container onClick={_handleProduct}  onMouseOver={_handleMouseOver} onMouseOut= {_handleMouseOut} >
          <div style={{display:"flex"}}>

            <div className="itemcontainer">
              <img src={img} class="image" style={{width:"150px",height:"150px"}}/>
              {/* <div className="middle">
                <div className="text">온라인 최저가 | {CommaFormatted(lowprice)}원</div>
                <div className="text">제품 구성품 | {component}</div>
                <div className="text">누적주문건수 | 1,230건</div>
                <div className="text">추가항목 | {add}</div>
              </div> */}
              {/* <Sticker1></Sticker1> */}
              {/* <div class="wrapper">
                {
                  existselect("newselect") == true &&
                  <div class="ribbon-wrapper-red">
                  <div class="ribbon-red">NEW</div>
                  </div>
                }

                {
                  existselect("mdselect") == true &&
                  <div class="ribbon-wrapper-red">
                  <div class="ribbon-red">MD추천</div>
                  </div>
                }
                {
                  existselect("bestselect") == true &&
                  <div class="ribbon-wrapper-green">
                  <div class="ribbon-green">BEST상품</div>
                  </div>
                }
                
                {
                  existselect("saleselect") == true &&
                  <div class="ribbon-wrapper-green">
                  <div class="ribbon-green">판매급상승</div>
                  </div>
                }
          
              </div> */}
            </div>
          </div>

      
          <ProductInfo>
          <div style={{display:"flex", marginTop:10, flexGrow:1}}> <ProductName>
              {name.slice(0, 17)}
          {name.length > 17 ? "..." : null}</ProductName></div>

          
          <div style={{display:"flex", flexGrow:1, marginTop:10}}>
          <div style={{fontSize: 13,textAlign: "left",paddingLeft: 10}}> 
          <span style={{color:"#929292", fontWeight:700}}>{'파트너 공급가   |   '}</span>
          <ProductPrice>{(price)}원</ProductPrice> 
          <ProductSubPrice>{(lowprice)}원</ProductSubPrice>  
          <ProductRate>{ratevalue(lowprice, price)}</ProductRate>    
          </div>
          </div>
          </ProductInfo>
          
    

        </Container>
    </Fragment>

  
  );
};

PickItem.defaultProps={
  price : 0,
  time :0,
}
export default PickItem;
