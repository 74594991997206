import React, { useContext, useEffect, useState } from 'react';
import { imageDB } from "../components/imageDB";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';

import '../components/css/common.css';

import useDimensions from 'react-use-dimensions'
import Button from '../components/Layout/Button';
import { COLORS } from '../components/Theme';
import Advertise from './component/Advertise';
import Label from './component/Label';
import Preview from './component/Categoryview';
import Noticeview from './component/Noticeview';
import Center from './component/Center';
import Best from './component/Best';
import Pick from './component/Pick';
import News from './component/News';
import Search from '../components/Layout/SearchBasket';
import MainItem from './component/MainItem';
import ProductItem from './component/ProductItem';
import OrderItem from './component/OrderItem';
import OrderInfo from './component/OrderInfo';
import ReceiveInfo from './component/ReceiveInfo';
import PayInfo from './component/PayInfo';
import { getBaskset } from '../utility/basketfirebase';
import { UserContext } from '../context/Users';
import { getproductbyid } from '../utility/productfirebase';



const Padding = styled.div`
  padding: 0px 10px;
`

const DataView =styled.div`
    border : 1px solid #a0a0a0;
    height : 80%;
    margin : 5px 5px 20px;
`

const Item = styled.div`
  display: flex;
  flex-direction : column;
  width:100%;
  margin-top:40px;
  height:50px;
`

const ItemTitle = styled.div`
  display: flex;
  font-size :16px;
`
const LineContent = styled.div`
  display: flex;
  width :100%;
  border-bottom :1px solid #e7e7e7;
`

const SelectView = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 250px;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom:10px;


`



const Basketcontainer = () => {


  const navigate = useNavigate();

  const [width, setWidth] = useState(100);
  const [ref, setRef] = useState(null);

  const [select , setSelect] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const {dispatch, user} = useContext(UserContext);

  const [basketlist, setBasketlist] = useState([]);

  const _handleRegis2 =() =>{
  
  }
  const onClickImg = () => {


  }
  const allselect = () =>{
    setSelect(!select);
  }

  useEffect(() => {

    //mount 시에
    console.log("init");

    window.scrollTo(0,0);

    return () => {

      //unmount 시에
      console.log("dispose");
    };
  }, []);

  const getFetchProduct = (PRODUCT_ID) =>{
    return new Promise((resolve, reject)=>{
      const productinfo =  getproductbyid(PRODUCT_ID);

      resolve(productinfo);
    })
  }

  useEffect(()=>{

    async function fetchData(){
      const USER_ID = user.USER_ID;

      const basketitems = await getBaskset({USER_ID});
      console.log("USER_ID", USER_ID, basketitems);
      for(let i = 0; i< basketitems.length; i++){
        let basketData= {
          basketimage : "",
          basketname : "",
          basketmodelname : "",
          basketcount : 0,
          basketproductprice : 0,
          
        }
        const PRODUCT_ID = basketitems[i].PRODUCT_ID;
        basketData.basketcount = basketitems[i].BASKET_COUNT;
        basketData.basketproductprice = basketitems[i].BASKET_PRICE;

        getFetchProduct(PRODUCT_ID).then((result)=>{
          basketData.basketimage = result.PRODUCTIMAGEARY[0];
          basketData.basketname = result.PRODUCTNAME;
          basketData.basketmodelname = result.PRODUCTMODELNAME;
          basketlist.push(basketData);
          setBasketlist(basketlist);
          console.log("basketlist", basketlist);

          setRefresh(true);
        })
     
        
    
      }
  
   
    }

    fetchData();


  }, [])

  useEffect(()=>{

    if(refresh){
      setBasketlist(basketlist);
    }

  }, [refresh])



  return (
    <div className ="Container">
      <div style={{marginTop:'20px', padding:"0px 15%"}}>
          <div style={{display:"flex", flexDirection:"column"}}>
             <MainItem ItemText={'장바구니(2건)'}/>

              <div style={{display:"flex", flexDirection:"row"}}>
                  <div style={{display:"flex", width:'70%', flexDirection:"column"}}>

                      <SelectView>
                        {
                          select == true ? (<img onClick={allselect}  src={imageDB.check} style={{width:"25px", height:"25px"}}/>) :
                          (<img  onClick={allselect} src={imageDB.uncheck} style={{width:"25px", height:"25px"}}/>)
                        }
                        
                        <div>전체선택</div>
                        <div>|</div>
                        <div>선택삭제</div>
                      </SelectView>
                      <LineContent/>

                      {
                        basketlist.map((data, index)=>(
                          <ProductItem key={index} image = {data.basketimage}
                          modelname ={data.basketmodelname}
                          price ={data.basketproductprice}
                          count ={data.basketcount}
                           name={data.basketname}/>
                        ))
                      }
                     
            
                   
                      <OrderInfo/>
                      <ReceiveInfo/>
                      <PayInfo/>

                  </div>
                  <div style={{display:"flex", width:'30%'}}>
                        <OrderItem/>
                  </div>


              </div>

          </div>
  
      </div>
    </div>
  );
}

export default Basketcontainer;
