

import React, { useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import Preview from "./Categoryview";
import Noticeview from "./Noticeview";
import BestItem from "./BestItem";
import Page from "../../components/Layout/Page";
import WithItem from "./WithItem";
import Fade from 'react-reveal/Fade';





const Tag = styled.div`
  font-size :20px;
  color : ${({enable})=> enable == true ?  ('#000') :('#999')};
  font-weight: 700;
  border-bottom : ${({enable})=> enable == true ?  ('2px solid #000') :('1px solid #999')};
  width: 25%;
  height:50px;

`

const Name = styled.div`
  background-color: #000;
  height: 60px;
  color: #fff;
  margin-top: 50px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  font-size: 20px;
  padding-left: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

const Content = styled.div`
  background-color: #fff;
  height: 200px;
  color: #000;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  font-size: 20px;
  flex-direction : column;
  border : 1px solid #ededed;
  margin-bottom:50px;

`
const ContentItem = styled.div`
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    border: 1px solid #ededed;


`
const ContentItemText = styled.div`
   padding-left:20px;
   font-size:16px;
`
const Explain = styled.div`

  background-color: #fff;
  height: 150px;
  color: #000;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  flex-direction: row;
  border: 1px solid #ededed;
  margin-bottom:50px;
`

const ExplainItem = styled.div`
  width: 40%;
  background: #f6f6f6;
  border : 1px solid #f6f6f6;
  height: 100px;
  margin-right:20px;
  margin-top:20px;
  display:flex;
  flex-diection : row;
  justify-content: center;
  align-items : center;
  padding: 0px 20px;
`

const ExplainSubItem1 = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
`
const ExplainSubItem1Content = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: 50px;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ExplainSubItem3Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
// const Tag = styled.div`
//   font-size :20px;
//   color : #999;
//   border-bottom: 1px solid #999;
//   width: 25%;
//   height:50px;
  
// `


const ProductInfo = ({product}) => {

  const [mode, setMode] = useState(0);

  const _handleInfo = ()=>{
    setMode(0);
  } 

  const _handlefaq = ()=>{
    setMode(1);
  }

  const _handleexplain = ()=>{
    setMode(2);
  }

  const _handlestrategy = ()=>{
    setMode(3);
  }

  return (
    <div style={{display:"flex",marginTop:50, padding:"0px 5%", flexDirection:"column"}}>
  
      <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between"}}>
        {mode ==0 ?(  <Tag onClick={_handleInfo} enable={true}>제품상세페이지</Tag>):(
            <Tag onClick={_handleInfo} enale={false}>제품상세페이지</Tag>
        )}

        {mode ==1 ?(  <Tag onClick={_handlefaq} enable={true}>제품 FAQ</Tag>):(
            <Tag onClick={_handlefaq} enale={false}>제품 FAQ</Tag>
        )}

        {mode ==2 ?(  <Tag onClick={_handleexplain} enable={true}>이미지 파일 다운로드</Tag>):(
            <Tag onClick={_handleexplain} enale={false}>이미지 파일 다운로드</Tag>
        )}

        {mode ==3 ?(  <Tag onClick={_handlestrategy} enable={true}>판매전략</Tag>):(
            <Tag onClick={_handlestrategy} enale={false}>판매전략</Tag>
        )}

      

      </div>

        {
          mode == 0 &&<div>

          {
            product.PRODUCT_VIDEOIMGARY.map((data, index)=>(
              <div style={{width:"100%", margin: '20px 0px' }}>
              <video  width="900px" height="500px"  controls autoPlay muted>
                  <source src={data} type="video/mp4" />
              </video>
              </div>
            ))
          }
            

          {
            product.PRODUCT_CONTENTCHECK == false ?  (
              product.PRODUCT_CONTENT_IMAGEARY.map((data, index)=>(
                <div style={{width:"100%", margin: '20px 0px' }}>
                  <img src={data} style={{width:"90%"}}/>
                </div>
          
              ))

            ):(
              <div style={{padding:"50px 0px", backgroundColor:"#fff"}} dangerouslySetInnerHTML={ {__html: product.PRODUCTCONTENT} }></div>

            )
          }

           </div>
        }
        {
          mode ==1 && <div>
            <Name>제품 FAQ</Name>
            <Content>
              <ContentItem>
                <ContentItemText>교환 및 환불 안내</ContentItemText>
                <div></div>
              </ContentItem>
              <ContentItem>
                <ContentItemText>주문 취소 안내</ContentItemText>
                <div></div>
              </ContentItem>
              <ContentItem>
                <ContentItemText>배송 관련 안내</ContentItemText>
                <div></div>
              </ContentItem>
            </Content>
          </div>
        }
        {
          mode ==2 && <div>
            <Name>제품 이미지 다운로드</Name>
            <Explain>
              <ExplainItem>
                  <ExplainSubItem1>
                    <ExplainSubItem1Content>
                      <img src={imageDB.pdf} style={{width:"50px"}}/>
                    </ExplainSubItem1Content>
                  </ExplainSubItem1>
                  <div style={{width:'60%'}}>
                    <div style={{fontSize:'16px'}}>제품썸네일 + 상세페이지</div>
                    <div style={{fontSize:'14px', paddingTop:10}}>다운로드</div>

                  </div>
                  <ExplainSubItem3Content>
                    <img src={imageDB.download} style={{width:"30px"}}/>
                  </ExplainSubItem3Content>
              </ExplainItem>
              <ExplainItem>
                  <ExplainSubItem1>
                    <ExplainSubItem1Content>
                    <img src={imageDB.ai} style={{width:"50px"}}/>
                    </ExplainSubItem1Content>
                  </ExplainSubItem1>
                  <div style={{width:'60%'}}>
                    <div style={{fontSize:'16px'}}>전체상품</div>
                    <div style={{fontSize:'14px', paddingTop:10}}>다운로드 하러 가기</div>
                  </div>
                  <ExplainSubItem3Content>
                    <img src={imageDB.download} style={{width:"30px"}}/>
                  </ExplainSubItem3Content>
              </ExplainItem>
            </Explain>
          </div>
        }

        {
          mode ==3 && <div>
            <Name>판매전략</Name>
            <Content></Content>
          </div>
        }


    </div>
  
  );
};

export default ProductInfo;
