

import React, { Fragment, useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import { imageDB } from "../../components/imageDB";
import Fade from 'react-reveal/Fade';

import { getBanner, getMainBanner } from "../../utility/bannerfirebase";

import ReactTyped from "react-typed";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import Ad from './Ad';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';




const MainBannerText = styled.div`
  font-size: 33px;
  position: relative;
  bottom: 550px;
  width: 30%;
  left: 145px;
  z-index: 2;
  color: ${({color})=> color};
  justify-content:flex-start;
  font-weight:900;
  font-family: Pretendard-Bold;
`
const SubBannerText = styled.div`
  font-size: 20px;
  position: relative;
  bottom: 450px;
  text-align:left;
  left: 145px;
  z-index: 2;
  width: 100%;
  color: ${({color})=> color};
  justify-content:flex-start;
  font-family: 'Pretendard-Regular';
`
const SubBannerText2 = styled.div`
  font-size: 20px;
  position: relative;
  bottom: 430px;
  text-align:left;
  left: 145px;
  z-index: 2;
  width: 100%;
  color: ${({color})=> color};
  justify-content:flex-start;
  font-family: 'Pretendard-Regular';
`

const BannerButtonView = styled.div`
  font-size: 20px;
  position: relative;
  left :155px;
  bottom: 202px;
  justify-content:flex-start;
  z-index: 2;


`
const BannerButton = styled.div`
  font-size: 20px;
  width: 200px;
  height:50px;
  border :1px solid #000;
  border-radius : 30px;
  color: #000;
  display:flex;
  justify-content:center;
  align-items:center;
  font-family: 'Pretendard-Regular';
`

const MainBanner = () => {
  const [banneritems, setBanneritems] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);


  const colorConvert = (color)=>{

    if(color =='흰색'){
      return "#fff";
    }else if(color =='검정색'){
      return "#000";
    }else if(color =='파란색'){
      return "#0000ff";
    }else if(color =='그린색'){
      return "#00ff00";
    }else if(color =='빨간색'){
      return "#ff0000";
    }
  }
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentSlide(next),
    // 다른 설정들...
  };

  const slideStyle = {
    marginLeft: swiperIndex === 0 ? '0%' : '-20%', // 이전 슬라이드의 일부를 보이도록 설정
  };
  useEffect(()=>{
    async function fetchData(){
      let bannerlist = [];
      let bannerlistTmp = [];
      bannerlistTmp = await getMainBanner();


      bannerlistTmp.forEach(element => {
        if(element.DISPOSE == true){
          bannerlist.push(element);
        }
      });

      setBanneritems(bannerlist);

      console.log("bannerlist", bannerlist);
    }

    fetchData();
	}, [refresh]);

  return (

    <div style={{flexDirection:"row", backgroundColor:"white", justifyContent:"center"}}>
        <img src={imageDB.banner} style={{width:"1400px", contain:"content"}}/>
        <MainBannerText color={"#fff"}>
              <Fade bottom delay={1000}>
                {'바딜이 여러분과 함께 합니다'}
              </Fade>
        </MainBannerText>
            
                

        <SubBannerText color={"#fff"}>
        <Fade bottom delay={2000}>
        {'1인 창조기업 해상물류사업을 시작으로 친환경 반려동물용품과  앱,홈페이지등 다양한 S/W개발'}

        </Fade>
        </SubBannerText>
        <SubBannerText2 color={"#fff"}>
        <Fade bottom delay={3000}>
        {' 기계/전자기기 H/W, 외국인 전용호텔의 K-FOOD 홍보를 위한 프리미엄 식품개발, IOT를 접목한 ICT 호텔 객실관리등 다양한 분야에 진출합니다.'}
        </Fade>
        </SubBannerText2>
        <BannerButtonView>

        </BannerButtonView>

    </div>
  
  );
};

export default MainBanner;
