
import React, { useContext, useEffect, useState } from 'react';

import { useNavigate} from "react-router-dom";
import styled from 'styled-components';
import '../components/css/common.css';
import Search from '../components/Layout/SearchBasket';
import Best from './component/Best';
import BestItem from './component/BestItem';
import { getproduct, getproductbyid } from '../utility/productfirebase';
import { Define } from '../components/Define';
import { MaxPC, SmallPC } from '../components/Responsive';
import { getcategory1, getcategory2 } from '../utility/categoryfirebase';
import ItemProduct from './component/ItemProduct';
import { imageDB } from '../components/imageDB';
import { deleteHeart, getHeart } from '../utility/heartfirebase';
import { UserContext } from '../context/Users';
import HeartItem from './component/HeartItem';
import { ref } from 'firebase/storage';




const Label = styled.div`
  margin-top: 40px;
  font-size: 30px;
  font-family: Pretendard-Bold;
`
const Filter = styled.div`
  height:60px;
  display:flex;
  flex-direction:row;
  border :1px solid #E1E1E1;
  background-color :#fff;
  align-items:center;
  margin-top:20px;
  border-radius:10px;
`

const FilterItems= styled.div`
  width:70%;
  display:flex;
  justify-content:space-evenly;
  font-size:16px;
`
const FilterSelect= styled.div`
  font-size:16px;
  font-weight:900;
  text-decoration:underline;
`

const FilterNoselect = styled.div`
  font-size :14px;
`
const ItemLayer = styled.div`
    display: flex;
    flex-flow: wrap;
    margin-top: 30px;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    padding : 0px 10%;
    min-height:500px;
    margin-bottom:100px;

`

const BigItemLayer = styled.div`
    display: flex;
    flex-flow: wrap;
    margin-top: 30px;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    padding : 0px 10%;
    min-height:500px;
    margin-bottom:100px;

`
const CategoryLayer = styled.div`
    background-color: #0f0f0f;
    margin: 20px 8%;
    border-radius: 5px;
    width: 80%;
    flex-direction: column;
    display: flex;
    padding: 0px 20px;
    min-height:120px;
`
const CategorySubLayer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: #c2c2c2;
    padding: 15px 0px;
    font-size: 16px;
    width: 80%;
    flex-direction:row;
    display:flex;
`
const CategorySub2Layer = styled.div`
    display: flex;
    flex-direction: row;
    color: #c2c2c2;
    padding: 10px 10px 10px 10px;
    font-size: 16px;

`
const CategorySubItem = styled.div`
  background-color : ${({select}) => select == true ? ("#fff") : ("#0f0f0f")};
  color : ${({select}) => select == true ? ("#000") : ("#c2c2c2")};
  border-radius:  ${({select}) => select == true ? ("20px") : (null)};
  padding: ${({select}) => select == true ? ("5px 20px") : ("5px 0px")};
  max-width:160px;
  min-width:140px;
  display: flex;
  justify-content: center;

`
const CategoryItem = styled.div`
  background-color : ${({select}) => select == true ? ("#a03838") : ("#0f0f0f")};
  color : ${({select}) => select == true ? ("#000") : ("#c2c2c2")};
  border-radius:  ${({select}) => select == true ? ("20px") : (null)};
  padding: ${({select}) => select == true ? ("5px 20px") : ("5px 0px")};
`
const NoProduct = styled.div`
  font-size:25px;
  font-weight:900;
`


const Heartproductcontainer = ({pagetype}) => {

  const {user, dispatch} = useContext(UserContext);

  const [productitems, setProductitems] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [heartproductidlist, setHeartproductidlist] = useState([]);


  const heartoff = async(HEART_ID) =>{
    console.log("heartoff", HEART_ID);
    const HeartDelete = await deleteHeart(HEART_ID);

    alert("찜 항목에서 제거 하였습니다");
    setRefresh(refresh=>refresh * -1);
  }

  useEffect(() => {

    //mount 시에
    console.log("init");

    window.scrollTo(0,0);

    return () => {

    };
  }, []);
  
  const getFetchProduct = (PRODUCT_ID) =>{
    return new Promise((resolve, reject)=>{
      const productinfo =   getproductbyid(PRODUCT_ID);

      resolve(productinfo);
    })
  }


  useEffect(()=>{
    const USER_ID = user.USER_ID;

    console.log("user", user);
    setProductitems([]);
    async function fetchData(){
      const items = await getHeart(USER_ID);

      console.log("items", items);

      let productitems = [];
      for(let i=0; i < items.length; i++){
        const PRODUCT_ID = items[i].PRODUCT_ID;

 
        getFetchProduct(PRODUCT_ID).then((result)=>{

          let itemPlus = {
            item : {},
            heartid : "",
          };
          itemPlus.item = result;
          itemPlus.heartid =items[i].HEART_ID;
          
          console.log("itemdata", itemPlus);
          productitems.push(itemPlus);
          console.log("productitems", productitems);
          setProductitems(productitems);

        })
      }


    }

    fetchData();
 
	}, [refresh]);







  return (
    <>
    <SmallPC>
    <div className ="SmallItemContainer">
      <div>
        <Label>{'찜목록'}</Label>
        <>
              {
                productitems.length == 0 &&   <>
                <img src={imageDB.mainlogo} style={{paddingTop:100, height:100}}/>
                <NoProduct>상품이 존재 하지 않습니다</NoProduct>
                </>
              }  
              <ItemLayer>    
                {
                  productitems.map((data, index)=>(
                    <HeartItem  key={index}  heart_id={data.heartid} callback={heartoff} product_id ={data.item.PRODUCT_ID} img={data.item.PRODUCTIMAGEARY[0]} name={data.item.PRODUCTNAME} select= {data.item.PRODUCTSELECT} time={100} 
                    price={data.item.PRODUCTREGULARPRICE} lowprice = {data.item.PRODUCTLOWPRICE} component ={data.item.PRODUCTCOMPONENT} add={data.item.PRODUCT_ADD11_INFO}/>
                  ))
                }
              </ItemLayer> 
        </>
      </div>
    </div>
    </SmallPC>
    <MaxPC>
      <div className ="MaxItemContainer">
      <div>
        <Label>{'찜목록'}</Label>
        <>
              {
                productitems.length == 0 && 
                <>
                <img src={imageDB.mainlogo} style={{paddingTop:100, height:100}}/>
                <NoProduct>상품이 존재 하지 않습니다</NoProduct>
                </>
              
              }  
              <BigItemLayer>   
          
                {
                  productitems.map((data, index)=>(
                    <HeartItem  key={index}   heart_id={data.heartid} callback={heartoff} product_id ={data.item.PRODUCT_ID} img={data.item.PRODUCTIMAGEARY[0]} name={data.item.PRODUCTNAME} select= {data.item.PRODUCTSELECT} time={100} 
                    price={data.item.PRODUCTREGULARPRICE} lowprice = {data.item.PRODUCTLOWPRICE} component ={data.item.PRODUCTCOMPONENT} add={data.item.PRODUCT_ADD11_INFO}/>
                  ))
                }
              </BigItemLayer> 
        </>
      </div>
    </div>  
    </MaxPC>
    </>

  
  );
}

export default Heartproductcontainer;
