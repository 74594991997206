
import React, { useEffect, useState } from 'react';

import { useNavigate} from "react-router-dom";
import styled from 'styled-components';
import '../components/css/common.css';
import Search from '../components/Layout/SearchBasket';
import Best from './component/Best';
import BestItem from './component/BestItem';
import { getproduct } from '../utility/productfirebase';
import { Define } from '../components/Define';
import { MaxPC, SmallPC } from '../components/Responsive';
import { getcategory1, getcategory2 } from '../utility/categoryfirebase';
import ItemProduct from './component/ItemProduct';
import { imageDB } from '../components/imageDB';
import BuyItem from './component/BuyItem';




const Label = styled.div`
  margin-top: 40px;
  font-size: 30px;
  font-family: Pretendard-Bold;
`
const Filter = styled.div`
  height:60px;
  display:flex;
  flex-direction:row;
  border :1px solid #E1E1E1;
  background-color :#fff;
  align-items:center;
  margin-top:20px;
  border-radius:10px;
`

const FilterItems= styled.div`
  width:70%;
  display:flex;
  justify-content:space-evenly;
  font-size:16px;
`
const FilterSelect= styled.div`
  font-size:16px;
  font-weight:900;
  text-decoration:underline;
`

const FilterNoselect = styled.div`
  font-size :14px;
`
const ItemLayer = styled.div`
    display: flex;
    flex-flow: wrap;
    margin-top: 30px;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    padding : 0px 10%;
    min-height:500px;
    margin-bottom:100px;

`

const BigItemLayer = styled.div`
    display: flex;
    flex-flow: wrap;
    margin-top: 30px;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    padding : 0px 10%;
    min-height:500px;
    margin-bottom:100px;

`
const CategoryLayer = styled.div`
    background-color: #0f0f0f;
    margin: 20px 8%;
    border-radius: 5px;
    width: 80%;
    flex-direction: column;
    display: flex;
    padding: 0px 20px;
    min-height:120px;
`
const CategorySubLayer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: #c2c2c2;
    padding: 15px 0px;
    font-size: 16px;
    width: 80%;
    flex-direction:row;
    display:flex;
`
const CategorySub2Layer = styled.div`
    display: flex;
    flex-direction: row;
    color: #c2c2c2;
    padding: 10px 10px 10px 10px;
    font-size: 16px;

`
const CategorySubItem = styled.div`
  background-color : ${({select}) => select == true ? ("#fff") : ("#0f0f0f")};
  color : ${({select}) => select == true ? ("#000") : ("#c2c2c2")};
  border-radius:  ${({select}) => select == true ? ("20px") : (null)};
  padding: ${({select}) => select == true ? ("5px 20px") : ("5px 0px")};
  max-width:160px;
  min-width:140px;
  display: flex;
  justify-content: center;

`
const CategoryItem = styled.div`
  background-color : ${({select}) => select == true ? ("#a03838") : ("#0f0f0f")};
  color : ${({select}) => select == true ? ("#000") : ("#c2c2c2")};
  border-radius:  ${({select}) => select == true ? ("20px") : (null)};
  padding: ${({select}) => select == true ? ("5px 20px") : ("5px 0px")};
`
const NoProduct = styled.div`
  font-size:25px;
  font-weight:900;
`

const Box  = styled.div`
  height:350px;
  width:23%;
  background-color:#ededed;
  margin-left:10px;
  margin-bottom:10px;
  padding:10px;
`

const Tag = styled.div`
background: black;
width: 120px;
display: flex;
justify-content: center;
color: #fff;
border-radius: 20px;
height: 30px;
align-items: center;
margin-bottom: 10px;
`

const Portfoliocontainer = ({pagetype}) => {

  console.log("pagetype", pagetype);

  const [productitems, setProductitems] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [categoryitems, setCategoryitems] = useState([]);
  const [categorysubitems, setCategorysubitems] = useState([]);

  const [category1, setCategory1] = useState('전체');
  const [category2, setCategory2] = useState('');

  const existcategroy = (cateogyitems, categoryname)=>{
    const FindIndex = cateogyitems.findIndex(x=>x == categoryname);

    if(FindIndex == -1){
      return false;
    }else{
      return true;
    }

  }

  const existproduct = (category1, category2)=>{



    if(category2 == '전체'){
      return true;
    }

    if(category1 == category2){
      return true;
    }else{
      return false;
    }

  }

  const existselectproduct2 = (categorya, categoryb)=>{

   if(category1 =='전체'){
     return false;
   }
    if(categoryb.indexOf('전체') > 0){
      console.log("category2 전체가 기본으로 선택", category2);
      return false;
    }else{
      return true;
    }
  }
  const existproduct2 = (categorya, categoryb)=>{

    console.log("existproduct2", category1, category2);

    if(categoryb.indexOf('전체') >0 ){
      return true;
    }

    if(categorya == categoryb){
      return true;
    }else{
      return false;
    }

  }

  const _handleCategory= async(data)=>{

    let categorylistTmp = [];
    let categorylist = [];

    let categoryitem = {
      CATEGORY1 : "",
      CATEGORY2 : "",
      CATEGORYIMG : "",
      REGISTDATE :""
    }
    categoryitem.CATEGORY2 = data.CATEGORY1 + "전체";

    categorylistTmp = await getcategory2(data.CATEGORY1);

    categorylist.push(categoryitem);

    categorylistTmp.forEach((element)=>{
      categorylist.push(element);
    })

    if(data.CATEGORY1 != '전체'){
      setCategorysubitems(categorylist);
    }else{
      setCategorysubitems([]);
    }

    setCategory1(data.CATEGORY1);
    setCategory2(data.CATEGORY1 +'전체');

    setRefresh(refresh => refresh *-1);

  }
  const _handleSubCategory= async(data)=>{


    console.log("SubCategory select", data);
    if(data.CATEGORY1 != '전체'){
      setCategory2(data.CATEGORY2);
    }else{
      setCategory2(data.CATEGORY1 + '전체');
    }
    setRefresh(refresh => refresh *-1);
  }

  useEffect(() => {

    //mount 시에
    console.log("init");

    window.scrollTo(0,0);

    return () => {

      //unmount 시에
      console.log("dispose");
    };
  }, []);
  
  useEffect(()=>{
    async function fetchData(){
      let productitemstmp = [];
      let productitems = [];
      productitemstmp = await getproduct();
      productitemstmp.forEach(element => {

        if( pagetype == Define.allcategory){

          if(existselectproduct2(element.PRODUCTTWOSELECT,category2)){

            console.log("two select");

            if(existproduct2(element.PRODUCTTWOSELECT, category2)){
              productitems.push(element);
            }

          }else if(existproduct(element.PRODUCTONESELECT, category1)){

            console.log("one select");
            productitems.push(element);
       
          }
        }
        else if( pagetype == Define.best){
          if(existcategroy(element.PRODUCTCATEGORY, "bestcategory")){
            productitems.push(element);
         }
        }else if( pagetype == Define.new){
          if(existcategroy(element.PRODUCTCATEGORY, "newcategory")){
            productitems.push(element);
         }
        }else if( pagetype == Define.md){
          if(existcategroy(element.PRODUCTCATEGORY, "mdcategory")){
            productitems.push(element);
          }
        }else if( pagetype == Define.gomargin){
          if(existcategroy(element.PRODUCTCATEGORY, "gomargincategory")){
            productitems.push(element);
          }
        }else if( pagetype == Define.top){
          if(existcategroy(element.PRODUCTCATEGORY, "top10category")){
            productitems.push(element);
          }
        }else if( pagetype == Define.soldout){
          if(existcategroy(element.PRODUCTCATEGORY, "tempoutcategory")){
            productitems.push(element);
          }
        }else if( pagetype == Define.out){
          if(existcategroy(element.PRODUCTCATEGORY, "outcategory")){
            productitems.push(element);
          }
        }
      
      
      });

      setProductitems(productitems);

      console.log("productitems", productitems);

    }

    fetchData();
  }, [refresh, pagetype]);

  useEffect(()=>{
    async function fetchData(){
      let categorylistTmp = [];
      let categorylist = [];

      let categoryitem = {
        CATEGORY1 : "",
        CATEGORY2 : "",
        CATEGORYIMG : "",
        REGISTDATE :""
      }
      categoryitem.CATEGORY1 = "전체";
      categorylistTmp = await getcategory1();
      categorylist.push(categoryitem);
      categorylistTmp.forEach((element)=>{
        categorylist.push(element);
      })

      setCategoryitems(categorylist);

      console.log("categorylist", categorylist);
    }

    fetchData();
	}, [refresh]);

  const navigate = useNavigate();

    const items = [{period:"2021.09 ~ 2021.11",
  name:"스마트 해상물류 O2O  배송플랫폼",
  info:"바리바리",
  tag:['앱개발','API 개발'],
  environment:"",
  language:"Angular + Firebase"},
  {period:"2023.01 ~ 2023.02",
  name:"반응형 앱 연동 홈페이지(kl-hum.com)",
  info:"울산 과학 기술연구원",
  tag:['웹개발','API 개발'],
  environment:"",
  language:"React + Firebase"},
  {period:"2023.02 ~ 2023.06",
  name:"울산 항만공사 자산 관리 운용앱",
  info:"울산항만공사",
  tag:["시스템 개발", "솔루션 앱 개발"],
  environment:"",
  language:"React + React-native + Firebase"},
  {period:"2023.07 ~ 2023.10",
  name:"객체인지/학습모델 개발",
  info:"(주)케이엘허브",
  tag:["시스템 개발", "솔루션 앱 개발"],
  environment:"",
  language:"REACT + THREE.js"},
  {period:"2023.07 ~ 2023.10",
  name:"컨테이너 항만 3D 모델링개발",
  info:"(주)케이엘허브",
  tag:["시스템 개발", "솔루션 앱 개발"],
  environment:"",
  language:"UG"},
  {period:"2023.10 ~ 2023.12",
  name:"항만크레인 3D 모델링개발",
  info:"울산과학기술원",
  tag:["앱개발", "솔루션 앱 개발"],
  environment:"",
  language:"UG"},
  ]



  return (
    <>
    <Label>{pagetype}</Label>

    <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap", padding:10,justifyContent:"center", textAlign:'left'}}>

      {
        items.map((data, index)=>(
          <Box>
            {
              data.tag.map((info, subindex) =>(
                <Tag>{info}</Tag>
              ))
            }


            <div style={{fontSize:30, fontWeight:900, marginTop:30}}>{data.name}</div>


            <div style={{fontSize:16, marginTop:70, textAlign:"right"}}>거래처명 : {data.info}</div>
            <div style={{fontSize:16, textAlign:"right"}}>개발기간 : {data.period}</div>
            <div style={{fontSize:16, textAlign:"right"}}>개발언어 : {data.language}</div>

          </Box>
        ))
      }
  

    </div>






    </>

  
  );
}

export default Portfoliocontainer;
