import React, { useContext, useEffect, useState } from 'react';
import Homecontainer from '../container/Homecontainer';
import Layout from '../components/Layout/Layout';
import { BannerContext } from '../context/Banner';
import { getSideBanner } from '../utility/bannerfirebase';
import Homelayout from '../components/Layout/Homelayout';

const Homepage = () => {

  const {banner, dispatch2} = useContext(BannerContext);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    window.scrollTo(0,0);
    async function fetchData(){
   
      let bannerlist = [];
      let bannerlistTmp = [];
      bannerlistTmp = await getSideBanner();

      bannerlistTmp.forEach(element => {
        if(element.DISPOSE == true){
          bannerlist.push(element);
        }
      });

      banner.bannerlist = bannerlist;
      dispatch2(banner);
      setRefresh(true);
    
    }
    fetchData();
    return () => {

    };
  }, []);

  return (
   refresh == true && <Homelayout>
      <Homecontainer/>
    </Homelayout>

  );
}

export default Homepage;
