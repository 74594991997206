

import React, { Fragment, useContext, useEffect, useState} from "react";
import { Mobile, PC } from "../../Responsive";
import { imageDB } from "../../imageDB";
import { COLORS } from "../../Theme";
import styled from "styled-components";
import Chat from "../../../container/component/Chat";
import Button from "../Button";
import Guide from "../../../container/component/Guide";
import ControlView from "../../../container/component/ControlView";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/Users";
import { signupUser } from "../../../utility/userfirebase";


const PCSide = styled.div`
  height: 300px;
  background-color: white;
  align-items: right;
  display: flex;
  position: absolute;
  right: 0;
  width: 50px;
  border-radius: 10px;
  z-index:2;
`
const PCSideL = styled.div`
  height: 300px;
  background-color: white;
  align-items: right;
  display: flex;
  position: absolute;
  right: 22%;
  width: 50px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index:3;

`
const PCSideExpand = styled.div`
  height: 900px;
  background-color: white;
  align-items: right;
  display: flex;
  flex-direction : column;
  position: absolute;
  right: 0;
  top:0;
  width: 22%;
  border-bottom-left-radius: 15px;
  align-items: center;
  z-index:5;

`

const Container = styled.div`
  display:flex;
  flex-direction: column;  
  justify-content: flex-start; 
  align-items: center;
  border-radius :10px;

`
const MainText = styled.span`
  font-weight :700;
  color : black;
`

const CheckBtn = styled.div`
    background-color: #fff;
    width: 30px;
    height: 30px;
    margin: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Btn = styled.div`
    width: 30px;
    height: 30px;
    margin: 10px;
    border-radius: 10px;
    display : flex;
    justify-content: center;
    align-items : center;
`

const Line = styled.div`
  background-color : #E7E7E7;
  height :1px;
  width : 30px;
`
const BankAccount = styled.div`
  display: flex;
  flex-direction : column;
  width:90%;
  margin-top:15px;

`
const Nego = styled.div`
  display: flex;
  flex-direction : column;
  width:90%;
  margin-top:15px;

`
const Customer = styled.div`
  display: flex;
  flex-direction : column;
  width:90%;
  margin-top:15px;

`


const Rightside = () => {
  const navigate = useNavigate();
  const [expand, setExpand] = useState(false);
  const {dispatch, user} = useContext(UserContext);

  const _handleExpand = () =>{
    setExpand(!expand);
  }
  const _handleRegister = ()=>{
    navigate("/Memberregister");
  }
  const _handleLogin = ()=>{
    navigate("/Memberlogin");
  }
  const _handleLogout = async()=>{
    const logoutuser = await signupUser();

    if(logoutuser != -1){

      user.USERID = "";
      user.USERPW = "";
      user.USERNAME = "";
      user.USER_ID = "";
      dispatch(user);

      navigate("/home");
    }
  }

  return (
    <Fragment>


    </Fragment>

  

  );
};

export default Rightside;