import React, { useState, useEffect, useContext, Fragment } from 'react';
import { imageDB } from "../components/imageDB";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';

import '../components/css/common.css';

import useDimensions from 'react-use-dimensions'
import Button from '../components/Layout/Button';
import { COLORS } from '../components/Theme';
import Advertise from './component/Advertise';
import Label from './component/Label';
import Preview from './component/Categoryview';
import Noticeview from './component/Noticeview';
import Center from './component/Center';
import Best from './component/Best';
import Pick from './component/Pick';
import News from './component/News';
import Search from '../components/Layout/SearchBasket';
import { getBanner, getSideBanner } from '../utility/bannerfirebase';
import { CategoryContext } from '../context/Category';
import { getcategory1, getcategory2 } from '../utility/categoryfirebase';
import { BannerContext } from '../context/Banner';
import Advertise2 from './component/Advertise2';
import Fade from 'react-reveal/Fade';
import New from './component/NewProduct';
import New2 from './component/NewProduct2';
import MainBanner from './component/MainBanner';
import NewProduct from './component/NewProduct';
import NewProduct2 from './component/NewProduct2';
import { MaxPC, SmallPC } from '../components/Responsive';
import MainMaxBanner from './component/MainMaxBanner';
import Companion from './component/Companion';
import ICT from './component/ICT';

const Padding = styled.div`

 
`

const DataView =styled.div`
    border : 1px solid #a0a0a0;
    height : 80%;
    margin : 5px 5px 20px;
`


const Homecontainer = () => {

  const navigate = useNavigate();


  const {category, dispatch} = useContext(CategoryContext);
  const {banner, dispatch2} = useContext(BannerContext);

  const [width, setWidth] = useState(100);
  const [refresh, setRefresh] = useState(1);
  const [banneritems, setBanneritems] = useState([]);
  const _handleRegist =() =>{
    navigate('/register')
  }

  useEffect(()=>{
    async function fetchData(){
      let bannerlist = [];
      bannerlist = await getBanner();

      setBanneritems(bannerlist);

      console.log("bannerlist", bannerlist);
    }

    fetchData();
	}, [refresh]);


  useEffect(() => {
    window.scrollTo(0,0);
    async function fetchData(){
   
      let totalcategory = [];
      let cagetegoryitems =[];
      cagetegoryitems=  await getcategory1();
      cagetegoryitems.forEach(async(element)=>{

       const category1 = element.CATEGORY1;

        let category = {
          category1 : "",
          category2 : [],
        }

        category.category1 = category1;

        let categorysubitems = await getcategory2(category1);
        categorysubitems.forEach((ele)=>{
          category.category2.push(ele.CATEGORY2);
        })


        totalcategory.push(category);

      })
      category.categorylist = totalcategory;
      dispatch(category);


      // let bannerlist = [];
      // let bannerlistTmp = [];
      // bannerlistTmp = await getSideBanner();

      // bannerlistTmp.forEach(element => {
      //   if(element.DISPOSE == true){
      //     bannerlist.push(element);
      //   }
      // });

      // banner.bannerlist = bannerlist;
      // dispatch2(banner);


  
    }
    fetchData();
    return () => {

    };
  }, []);
  return (
    <>
    <SmallPC>
        <Advertise/>
        <div className ="Homecontainer">  
          <Center />
        </div>
        <div className ="HomeNewcontainer">
          <Companion />
        </div>
        <div className ="Homecontainer">
          <ICT />
        </div>
        {/* <Advertise/>   */}
        {/* <div className ="Homecontainer"> 
          <Pick /> 
          <News />
          <NewProduct2 />
        </div> */}
    </SmallPC>
    <MaxPC>
      <Advertise/>
      <div className ="HomeMaxcontainer">  
          <Center />
      </div>
      <div className ="HomeNewMaxcontainer">
          <Companion />
      </div>
      <div className ="HomeMaxcontainer">
          <ICT />
      </div>

      {/* <div className ="HomeMaxcontainer"> 
          <Pick /> 
          <News />
          <NewProduct2 />
      </div> */}
    </MaxPC>
  

      
    </>

  


  
  );
}

export default Homecontainer;
