

import React, { Fragment, useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import PreviewItem from "./CategoryviewItem";
import Page from "../../components/Layout/Page";
import { getcategory1 } from "../../utility/categoryfirebase";
import CategoryviewItem from "./CategoryviewItem";
import SubLabel from "./SubLabel";


const Categoryview = () => {

  const [cateogoryitems, setCategoryitems] = 
  useState([{CATEGORYIMG : imageDB.main1, CATEGORY1:'스마트물류사업'},
  {CATEGORYIMG : imageDB.main2, CATEGORY1:'반려동물용품'},
  {CATEGORYIMG : imageDB.main3, CATEGORY1:'ICT사업부'},
  {CATEGORYIMG : imageDB.main4, CATEGORY1:'기계전자기기'},
  {CATEGORYIMG : imageDB.main5, CATEGORY1:'호텔시설관리'},
  {CATEGORYIMG : imageDB.main6, CATEGORY1:'호텔식품제조'},
  {CATEGORYIMG : imageDB.main7, CATEGORY1:'수입차부품'},
  {CATEGORYIMG : imageDB.main8, CATEGORY1:'의류사업'}]);
  const [refresh, setRefresh] = useState(1);
  useEffect(()=>{

	}, [refresh]);

  return (

    <Fragment>
    <Label name={'사업분야'} weight={900} />
    {/* <div style={{height:10}}/> */}
    {/* <SubLabel name={'머레이코리아 카테고리를 한눈에 쉽게 찾아보세요'}  weight={100} color={'#999'}/> */}
    <div style={{display:"flex", flexDirection:"column", borderRadius: 5, width:'100%', paddingLeft:30}}>

      {/* <div style={{display:"flex", justifyContent:"space-between"}}>
        <Label name={'제품별 둘러보기'} weight={600}/>
      
      </div> */}




      <div style={{display:"flex", flexDirection:"row", marginTop:15, flexWrap:"wrap",
        justifyContent: 'space-around', padding:"20px 0px"}}>



        {
          cateogoryitems.map((data, index)=>(
            <CategoryviewItem img={data.CATEGORYIMG} name={data.CATEGORY1}/>  
          ))
        }

      </div>




    </div>
    </Fragment>


  
  );
};

export default Categoryview;
